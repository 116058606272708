import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const idpolicy = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">ID Policy</h1>

      <h2 className="title">Candidate Identification Policy</h2>

      <p>
        We appreciate your interest in pursuing a COITB certification. An
        essential step in your exam preparation is ensuring you possess the
        requisite forms of identification prior to registering for the exam.
        This document outlines COITB's criteria for acceptable identification.
        It is imperative for candidates undertaking professional level exams to
        have corresponding professional level primary identification.
        State-issued IDs should be current and accessible, with all necessary
        identification acquired before exam registration. Please be advised that
        no exceptions to this policy will be granted.
      </p>

      <h3 className="title">Identification (ID) Requirements:</h3>

      <ul>
        <li>
          The name used by the candidate to register must precisely match the
          first and last names on the IDs presented on the day of the test.
        </li>
        <li>
          All required IDs must be issued by the country in which the candidate
          is taking the test. Candidates without a qualifying primary ID from
          the testing country must present an International Travel Passport from
          their country of citizenship, alongside a secondary form of ID.
        </li>
        <li>
          Candidates must present two original (no photocopies), valid (not
          expired) forms of ID: one primary ID (government-issued with the
          candidate's name, a recent recognizable photo, and a signature) and
          one secondary ID (with at least a name and signature or name and
          recent recognizable photo).
        </li>
      </ul>

      <h4 className="title">Testing Center (In-Person) ID Policy:</h4>

      <p>Primary ID | Secondary ID</p>
      <ul>
        <li>International Travel Passport</li>
        <li>Driver’s license from any U.S. State or Territory</li>
        <li>Military ID (including spouse & dependents)</li>
        <li>National/state/province identification card</li>
        <li>Alien registration card (green card, permanent resident, visa)</li>
      </ul>

      <h5 className="title">Online Proctored ID Policy:</h5>

      <p>
        To uphold the integrity of our online proctoring process and prevent
        impersonation, it is mandatory for candidates to submit scans of both
        the front and back sides of a valid identification card. These documents
        should be sent to support@coitb.org, where a proctoring official will
        review and confirm the identity of the candidate prior to the
        commencement of the exam. Upon the successful completion of the
        examination, the proctoring official will ensure the secure deletion of
        the email containing the scanned ID photos, maintaining the
        confidentiality and privacy of the candidate's personal information.
      </p>
      <p>Primary ID | Secondary ID</p>
      <ul>
        <li>
          Similar to the Testing Center ID Policy with the stipulation regarding
          restricted IDs.
        </li>
      </ul>
      <p>Additional Guidelines:</p>
      <ul>
        <li>
          <span style={{ color: "red" }}>Expired IDs are not valid.</span>
        </li>
        <li>
          If a government-issued ID lacks a visible signature (or features an
          embedded signature), the candidate may still test, provided the other
          ID requirements are met.
        </li>
      </ul>

      <h6 className="title">
        Special Considerations for Minors/Students (Age 17 and Under):
      </h6>

      <ul>
        <li>
          Applicable to minors/students with only one form of photo ID (a school
          ID is permissible).
        </li>
        <li>
          A parent or legal guardian with valid government-issued ID must
          accompany the minor/student to the testing center to verify the
          student's identity.
        </li>
        <li>
          The parent or legal guardian is required to sign next to the
          minor/student on the log-in sheet as an identity verification measure.
        </li>
        <li>
          <span style={{ color: "red" }}>
            Each testing event must be authorized by a parent or legal guardian
            for the minor/student.
          </span>
        </li>
      </ul>
    </div>
  );
};

export default idpolicy;
