import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const examContentPage = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Exam Content</h1>

      <p>
        In maintaining the highest level of integrity and security for our
        examinations, COITB strictly prohibits the dissemination of exam
        questions and answers to the public. Our commitment to ensuring a fair
        and equitable certification process means that access to exam content is
        strictly confined within the parameters of the examination process
        itself. We do not endorse, nor do we engage in the practice of sharing
        exam materials with external organizations, partners, or vendors,
        regardless of their relationship with COITB. It is important to be aware
        that unauthorized entities may attempt to distribute inaccurate or
        counterfeit exam materials. We strongly advise against reliance on such
        sources, as they do not represent the quality or the integrity of
        COITB's certification process.{" "}
      </p>
    </div>
  );
};

export default examContentPage;
