import React, { useState } from 'react';
import { Modal, Form, FloatingLabel, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import "./paymentOption.css";

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string().required('Card number is required.'),
  cvv2: Yup.number().required('3-4 digit cvv is required'),
  expiryMonth: Yup.number().required('Expiration month is required'),
  expiryYear: Yup.number().required('Expiration year is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  region: Yup.string().required('State is required'),
  postal: Yup.string().required('Zip code is required')
});

const PaymentForm = ({ initialValues, onPrev, onChange }) => {
  const [visible, setVisible] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleClose = () => setVisible(false);
  const handleConfirmPayment = () => setShowConfirmation(true);
  const handleCancelConfirmation = () => setShowConfirmation(false);
  const handleConfirmPurchase = () => {
    // Add your payment confirmation logic here
    console.log("Payment Confirmed!");
    setShowConfirmation(false);
  };

  return (
    visible && (
      <>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onPrev={onPrev}
          >
            {({ handleSubmit }) => (
              <Form noValidate onSubmit={(e) => {
                e.preventDefault();
                handleConfirmPayment();
              }}>
                <div className="payment-option">
                  <div className="rectangle">
                    <button className="close-button" onClick={handleClose}>&times;</button>
                    <div className="content">
                      <div className="left-side">
                        <div className="payment-header">
                          <div className="price">$999.99</div>
                          <div className="title">JavaScript Professional Developer Online Exam</div>
                        </div>
                        <hr className="divider" />
                        <div className="invoice-details">
                          <div className="invoice-header">Certification Exam Invoice</div>
                          <p className="invoice-text">Review your order and fill data to complete payment. ALL SALES ARE FINAL</p>
                          <div className="details-header">Details</div>
                          <p className="details-text">The purchase of this exam is to gain certification as a professional JavaScript developer.</p>
                          <div className="invoice-items-header">Invoice items</div>
                          <div className="invoice-item-container">
                            <div className="invoice-item">
                              <span className="item-description">JavaScript Professional Developer Online Exam</span>
                              <span className="item-price">$999.99</span>
                            </div>
                          </div>
                          <div className="invoice-summary">
                            <div className="invoice-summary-item">
                              <span className="summary-label">Subtotal</span>
                              <span className="summary-value">$999.99</span>
                            </div>
                            <hr className="divider" />
                            <div className="invoice-summary-item">
                              <span className="summary-label">Total</span>
                              <span className="summary-value">$999.99</span>
                            </div>
                          </div>
                          <p className="final-text">All purchases made are Final</p>
                        </div>
                      </div>
                      <hr className="vertical-divider" />
                      <div className="right-side">
                        <div className="payment-header">Payment Details</div>
                        <div className="payment-details">
                          <div className="input-group">
                            <label htmlFor="name">Your Name</label>
                            <input type="text" id="name" placeholder="Your Name" />
                          </div>
                          <div className="input-group">
                            <FloatingLabel controlId='cardNumber' label='Card number'>
                              <Form.Control type="text" name="cardNumber" value={initialValues.cardNumber} onChange={onChange} placeholder='Card number' />
                            </FloatingLabel>
                          </div>
                          <div className="input-row">
                            <div className="input-group">
                              <FloatingLabel controlId='expiryMonth' label='Month'>
                                <Form.Control type="text" name="expiryMonth" value={initialValues.expiryMonth} onChange={onChange} placeholder='MM' />
                              </FloatingLabel>
                            </div>
                            <div className="input-group">
                              <FloatingLabel controlId='expiryYear' label='Year'>
                                <Form.Control type="text" name="expiryYear" value={initialValues.expiryYear} onChange={onChange} placeholder='YYYY' />
                              </FloatingLabel>
                            </div>
                            <div className="input-group">
                              <FloatingLabel controlId='cvv2' label='CVV'>
                                <Form.Control type="text" name="cvv2" value={initialValues.cvv2} onChange={onChange} placeholder='CVV' />
                              </FloatingLabel>
                            </div>
                            <div className="input-group">
                              <FloatingLabel controlId='postal' label='Zip Code'>
                                <Form.Control type="text" name="postal" value={initialValues.postal} onChange={onChange} placeholder='Zip Code' />
                              </FloatingLabel>
                            </div>
                          </div>
                          <div className="terms">
                            <input type="checkbox" id="terms" />
                            <label htmlFor="terms">I agree with all terms and conditions and testing policies</label>
                          </div>
                          <button className="confirm-button" type="submit">Confirm Payment</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>

        <Modal show={showConfirmation} onHide={handleCancelConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Purchase</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to confirm this purchase?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelConfirmation}>
              Cancel
            </Button>
            <Button 
              variant="primary" 
              onClick={handleConfirmPurchase}
              style={{ backgroundColor: '#055487', borderColor: '#055487' }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
};

export default PaymentForm;










  {/* <Modal.Footer>
            {isLoading ? (
                <BeatLoader color='#36D7B7' loading={isLoading} />
            ): (
                <>
                    {approval === 'approved' ? (
                        <FaCircleCheck color='green' size={30} />
                    ) : approval === 'denied' ? (
                        <FaCircleXmark color='red' size={30} />
                    ): null}
                </>
            )}
        </Modal.Footer> */}









// import React from 'react';
// import { Modal, Form, FloatingLabel, Button } from 'react-bootstrap';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
// import './paymentOption.css';

// const validationSchema = Yup.object().shape({
//     cardNumber: Yup.string().required('Card number is required.'),
//     cvv: Yup.number().required('CVV is required').typeError('CVV must be a number'),
//     expiryDate: Yup.string().required('Expiration date is required'),
//     name: Yup.string().required('Name is required.'),
//     email: Yup.string().email('Invalid email').required('E-Mail is required.'),
//     phone: Yup.string().required('Phone number is required.'),
//     terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions.'),
// });

// const PaymentForm = ({ initialValues, onSubmit }) => {
//     return (
//         <Modal show={true} onHide={() => {}} centered>
//             <Modal.Header closeButton>
//                 <Modal.Title>JavaScript Professional Developer Online Exam</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <div className="invoice-details">
//                     <div className="payment-header">
//                         <div className="price">$999.99</div>
//                         <div className="title">JavaScript Professional Developer Online Exam</div>
//                     </div>
//                     <hr className="divider" />
//                     <div className="invoice-header">Certification Exam Invoice</div>
//                     <p className="invoice-text">Review your order and fill data to complete payment. ALL SALES ARE FINAL</p>
//                     <div className="details-header">Details</div>
//                     <p className="details-text">The purchase of this exam is to gain certification as a professional JavaScript developer.</p>
//                     <div className="invoice-items-header">Invoice items</div>
//                     <div className="invoice-item-container">
//                         <div className="invoice-item">
//                             <span className="item-description">JavaScript Professional Developer Online Exam</span>
//                             <span className="item-price">$999.99</span>
//                         </div>
//                     </div>
//                     <div className="invoice-summary">
//                         <div className="invoice-summary-item">
//                             <span className="summary-label">Subtotal</span>
//                             <span className="summary-value">$999.99</span>
//                         </div>
//                         <hr className="divider" />
//                         <div className="invoice-summary-item">
//                             <span className="summary-label">Total</span>
//                             <span className="summary-value">$999.99</span>
//                         </div>
//                     </div>
//                     <p className="final-text">All purchases made are Final</p>
//                 </div>
//                 <Formik
//                     initialValues={initialValues}
//                     validationSchema={validationSchema}
//                     onSubmit={onSubmit}
//                 >
//                     {({ values, handleChange, handleSubmit, errors, touched }) => (
//                         <Form noValidate onSubmit={handleSubmit}>
//                             <div className="form-group">
//                                 <FloatingLabel controlId='name' label='Your Name' className='form-control-label'>
//                                     <Form.Control
//                                         type="text"
//                                         name="name"
//                                         value={values.name}
//                                         onChange={handleChange}
//                                         isInvalid={!!errors.name && touched.name}
//                                     />
//                                     <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
//                                 </FloatingLabel>
//                             </div>
//                             <div className="form-group">
//                                 <FloatingLabel controlId='email' label='E-Mail' className='form-control-label'>
//                                     <Form.Control
//                                         type="email"
//                                         name="email"
//                                         value={values.email}
//                                         onChange={handleChange}
//                                         isInvalid={!!errors.email && touched.email}
//                                     />
//                                     <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
//                                 </FloatingLabel>
//                             </div>
//                             <div className="form-group">
//                                 <FloatingLabel controlId='phone' label='Phone' className='form-control-label'>
//                                     <Form.Control
//                                         type="tel"
//                                         name="phone"
//                                         value={values.phone}
//                                         onChange={handleChange}
//                                         isInvalid={!!errors.phone && touched.phone}
//                                     />
//                                     <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
//                                 </FloatingLabel>
//                             </div>
//                             <div className="form-group">
//                                 <FloatingLabel controlId='cardNumber' label='Card Number' className='form-control-label'>
//                                     <Form.Control
//                                         type="text"
//                                         name="cardNumber"
//                                         value={values.cardNumber}
//                                         onChange={handleChange}
//                                         isInvalid={!!errors.cardNumber && touched.cardNumber}
//                                     />
//                                     <Form.Control.Feedback type="invalid">{errors.cardNumber}</Form.Control.Feedback>
//                                 </FloatingLabel>
//                             </div>
//                             <div className="input-row form-group">
//                                 <div className="input-group">
//                                     <FloatingLabel controlId='expiryDate' label='MM/YY' className='form-control-label'>
//                                         <Form.Control
//                                             type="text"
//                                             name="expiryDate"
//                                             value={values.expiryDate}
//                                             onChange={handleChange}
//                                             isInvalid={!!errors.expiryDate && touched.expiryDate}
//                                         />
//                                         <Form.Control.Feedback type="invalid">{errors.expiryDate}</Form.Control.Feedback>
//                                     </FloatingLabel>
//                                 </div>
//                                 <div className="input-group">
//                                     <FloatingLabel controlId='cvv' label='CVV' className='form-control-label'>
//                                         <Form.Control
//                                             type="text"
//                                             name="cvv"
//                                             value={values.cvv}
//                                             onChange={handleChange}
//                                             isInvalid={!!errors.cvv && touched.cvv}
//                                         />
//                                         <Form.Control.Feedback type="invalid">{errors.cvv}</Form.Control.Feedback>
//                                     </FloatingLabel>
//                                 </div>
//                             </div>
//                             <div className="terms form-group">
//                                 <Form.Check
//                                     type="checkbox"
//                                     id="terms"
//                                     name="terms"
//                                     label="I agree with all terms and conditions and testing policies"
//                                     value={values.terms}
//                                     onChange={handleChange}
//                                     isInvalid={!!errors.terms && touched.terms}
//                                 />
//                                 <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
//                             </div>
//                             <Button type="submit" className="confirm-button">Confirm Payment</Button>
//                         </Form>
//                     )}
//                 </Formik>
//             </Modal.Body>
//         </Modal>
//     );
// };

// export default PaymentForm;

      
    




/* <Modal.Footer>
            {isLoading ? (
                <BeatLoader color='#36D7B7' loading={isLoading} />
            ): (
                <>
                    {approval === 'approved' ? (
                        <FaCircleCheck color='green' size={30} />
                    ) : approval === 'denied' ? (
                        <FaCircleXmark color='red' size={30} />
                    ): null}
                </>
            )}
        </Modal.Footer> */