import React from 'react';
import "./testingOptions.css"; 

const TestingPage = () => {
  return (
    <div className="testing-page">
      <h1>Testing Options:</h1>
      <p>
        Gain a comprehensive understanding online testing formats. Learn what to anticipate to approach your exam with confidence. Access valuable preparation tips that encompass everything from effective study techniques to test-taking strategies.
      </p>
      

      <h2>Online Testing</h2>
      <p>Online testing provides the convenience and flexibility to complete your certification exam from any secure, distraction-free location at any time.</p>
      
      <h3>Why some examiners may consider online testing:</h3>
      <ul>
        <li>A secure and convenient way to test from home, a closed-door office, or any location with a private area.</li>
        <li>The flexibility to schedule your exam at any hour that suits you.</li>
        <li>Access to technical support in case of issues during your exam session.</li>
      </ul>
      
      <h3>Requirements for online testing:</h3>
      <ul>
        <li>A quiet, enclosed, and private location.</li>
        <li>A reliable device that meets system requirements and is equipped with a webcam.</li>
        <li>A strong, consistent internet connection with a minimum speed of 1 Mbps for both download and upload.</li>
        <li>Well-lit location</li>
      </ul>
      
      <h3>Rules for online exam taking:</h3>
      <p>Take an Exam Online</p>
      <p>Online testing, facilitated by COITB, offers a secure and convenient method to complete your COITB certification exam from any location that provides a private area. To take an exam online, follow these steps:</p>
      
      <h4>STEP 1: Review our testing Policies and Procedures</h4>
      <p>Before taking a COITB exam, all certification candidates must agree to the COITB Candidate Agreement. Failure to accept this agreement will result in the forfeiture of your exam fee. Additionally, there are specific policies and procedures for online testing, including those related to system requirements, exam workspace setup, and testing for candidates under 17 years of age. Please review them thoroughly:</p>
      <ul>
        <li><a href="/testing-policies">COITB Testing Policies</a></li>
        <li><a href="/testing/testing-agreement">COITB Candidate Agreement</a></li>
      </ul>
      
      <h4>STEP 2: Test the same computer and network you will use to take the exam</h4>
      <p>The system test takes approximately 5 to 10 minutes. During this test, you will be required to take photos of yourself and your exam workspace. To prepare your workspace, remove all books and writing materials from within reach, disconnect additional monitors and computers, and clear any writing from your walls (e.g., whiteboards). You will also need an ID and a phone, which must be placed out of reach after check-in.</p>
      <p>Ensure that your display settings meet the minimum screen resolution requirements and are set to 100% scaling (for Windows users) or default (for Mac users). Incorrect scale settings may cause the exam content to display improperly.</p>
      <p>To avoid delays and unnecessary stress, complete the system test well in advance of your exam appointment.</p>
      
      <h4>STEP 3: Create a COITB account if you don’t have one</h4>
      <p>Use an active, valid email address when creating your account. Important information regarding your exam results and certification status will be sent to this email address. Be sure to check your spam or junk folder in a timely manner if you have not received email confirmation of your account registration.</p>
      
      <h4>STEP 4: Log into your COITB account to schedule your online exam</h4>
      <p>When ready, visit the COITB website to prepare your testing environment and schedule your exam. Ensure all computer, internet connection, and workspace requirements are met before scheduling.</p>
      
      <h4>STEP 5: Return to the COITB website to sign in and take your exam</h4>
      <p>On the day of your exam, return to the COITB website, sign in, and select "Ready to Take Exam" tab to launch your scheduled online exam.</p>
      <p><strong>Note:</strong> Proctors for testing only speak English</p>

      <h2>Questions?</h2>
      contact us support@coitb.org
    </div>
  );
};

export default TestingPage;
