import { useState } from "react";
import styles from "./CourseCard.module.css";
import AccountSignupStepper from "../../components/forms/AccountSignupStepper";
import PaymentStepper from "../../components/forms/PaymentStepper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "@mui/material";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
export default function CourseCard({ certification, id, imageSRC }) {
  const [selectedTab, setSelectedTab] = useState(
    certification.available ? "Exam" : "Unavailable"
  );
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleExamPaymentButtonClick = () => {
    setShowPaymentForm(true);
  };

  const handleSignupButtonClick = () => {
    setShowSignupForm(true);
  };
  const handlePrepButtonClick = () => {
    window.location.href = "/partners";
  };

  const renderDescription = (description) => {
    const parts = description.split(
      /('syntax'|'functions'|'controls'|'MySQL fundamentals'|'data type and table manipulation'|'data querying with SQL'|'scope'|'arrays'|'loops'|'iterators'|'objects')/g
    );
    return parts.map((part, index) => {
      if (
        part === "'syntax'" ||
        part === "'functions'" ||
        part === "'controls'" ||
        part === "'MySQL fundamentals'" ||
        part === "'data type and table manipulation'" ||
        part === "'data querying with SQL'" ||
        part === "'syntax'" ||
        part === "'functions'" ||
        part === "'controls'" ||
        part === "'MySQL fundamentals'" ||
        part === "'data type and table manipulation'" ||
        part === "'data querying with SQL'" ||
        part === "'scope'" ||
        part === "'arrays'" ||
        part === "'loops'" ||
        part === "'iterators'" ||
        part === "'objects'"
      ) {
        return <b key={index}>{part}</b>;
      }
      return part;
    });
  };

  return (
    <div className={styles.credentialsPageCardBody} key={id}>
      {!certification.available ? (
        <div className={styles.credentialsPageCardBodyUnavailable}>
          <h1>Coming Soon!</h1>
          <br />
          <p style={{ width: '80%'}}>
          This credential is currently undergoing an update. Please check back later.
          </p>
        </div>
      ) : null}
      <div
        className={styles.credentialsPageCardHeader}
        style={{ backgroundImage: `url(${imageSRC})`, position: "relative" }}
      >
        {!certification.available ? (
          null
        ) : (
          <Chip
            className={styles.priceChip}
            label={`${certification.nonMemberPrice}`}
          />
        )}
      </div>
      {certification.available ? (
        <Tabs
          onChange={handleChange}
          aria-label="Course options tabs"
          variant="fullWidth"
          value={selectedTab}
          sx={{
            width: "100%",
            color: "black !important",
            marginBottom: "1rem",
            "& .MuiTabs-indicator": {
              backgroundColor: "black !important",
              color: "black !important",
            },
            "& .MuiButtonBase-root": {
              color: "black",
              borderBottom: "2px solid #3f3f3f62",
            },
            "& .Mui-selected": {
              color: "black !important",
              borderBottom: "2px solid black",
            },
          }}
        >
          <Tab label="Exam" value="Exam" />
          <Tab label="Outline" value="Outline" />
        </Tabs>
      ) : (
        null
      )}
      {selectedTab === "Exam" && (
        <>
          <div className={styles.cardTextContent}>
            <h3>
              {certification.title}
              <IoIosInformationCircleOutline
                style={{
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  marginLeft: "1rem",
                }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
            </h3>
            <p>{renderDescription(certification.description)}</p>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <p className={styles.popOver}>
                <strong>
                  <em>
                    No prior training or membership is required to take this
                    exam.
                  </em>
                </strong>
                <hr />
                <strong>
                  <em>Re-certification is required every 3 years.</em>
                </strong>
              </p>
            </Popover>
          </div>
          <div style={{ display: "flex", gap: "1.5rem" }}>
            <Button
              className={styles.credentialsPricingButton}
              onClick={handleExamPaymentButtonClick}
              sx={{
                borderBottom: "3px solid black",
                color: "black",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
                "& .MuiTouchRipple-root": {
                  color: "black",
                },
                gap: "0.5rem",
              }}
            >
              Buy Exam <FaArrowRightLong />
            </Button>
            <Button
              className={styles.credentialsPricingButton}
              onClick={() =>
                (window.location.href = `${certification.learnMore}`)
              }
              sx={{
                borderBottom: "3px solid black",
                color: "black",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
                "& .MuiTouchRipple-root": {
                  color: "black",
                },
                gap: "0.5rem",
              }}
            >
              learn more <FaArrowRightLong />
            </Button>
          </div>
          {showPaymentForm && (
            <PaymentStepper
              certification={certification}
              show={showPaymentForm}
              handleClose={() => setShowPaymentForm(false)}
            />
          )}
        </>
      )}
      {selectedTab === "Outline" && (
        <>
          <div className={styles.cardTextContent}>
            <h3>{certification.title}</h3>
            <p>{renderDescription(certification.description)}</p>
            <ul>
              {certification.outline.domains.map((domain, index) => (
                <li key={index}>
                  <b>
                    <strong>{domain.title}</strong>: {domain.description}
                  </b>
                </li>
              ))}
            </ul>
          </div>
          <p>
            Interested in a prep course? Check out what some of our partners
            have!
          </p>
          <Button
            className={styles.credentialsPricingButton}
            onClick={handlePrepButtonClick}
            sx={{
              borderBottom: "3px solid black",
              color: "black",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
              "& .MuiTouchRipple-root": {
                color: "black",
              },
              gap: "0.5rem",
            }}
          >
            Partners <FaArrowRightLong />
          </Button>
        </>
      )}
      {selectedTab === "Unavailable" && (
        <div className={styles.cardTextContent}>
          <h3>{certification.title}</h3>
          <p>{certification.description}</p>
        </div>
      )}
    </div>
  );
}
