import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const ComplaintProcessPolicy = () => {
  return (
    <div className="container">
         <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="policy-title">Complaint Process Policy</h1>
      <h2 className="policy-sub-title">Purpose:</h2>
      <p>
        This policy outlines the procedures for handling complaints related to the
        certification program. The objective is to provide a fair and transparent
        process for addressing grievances and concerns raised by stakeholders,
        including candidates, certified individuals, and other parties involved in the
        certification process.
      </p>
      <h2 className="policy-sub-title">1. Scope:</h2>
      <p>
        This policy applies to all complaints received regarding any aspect of the
        certification program, including but not limited to examination administration,
        certification eligibility, recertification requirements, conduct of certified
        individuals, and adherence to certification policies and procedures.
      </p>
      <h2 className="policy-sub-title">2. Submission of Complaints:</h2>
      <p>
        Complaints must be submitted in writing to the <a href="mailto:support@coitb.org">support@coitb.org</a> email
        within <span className="policy-emphasis">30 days</span> of the occurrence or discovery of the issue. Complaints
        should include detailed information regarding the nature of the complaint,
        relevant facts, supporting evidence, and the desired resolution.
        See Appendix A for example complaint templates.
      </p>
      <h2 className="policy-sub-title">3. Complaint Handling Process:</h2>
      <p>
        Upon receipt of a complaint, the <span className="policy-emphasis">Designated Authority</span> will initiate an
        investigation to assess the validity of the allegations and determine the
        appropriate course of action. The investigation may involve gathering
        additional information, interviewing relevant parties, and reviewing
        documentation as necessary.
      </p>
      <h2 className="policy-sub-title">4. Resolution:</h2>
      <p>
        Efforts will be made to resolve complaints in a timely and fair manner.
        Depending on the nature and severity of the complaint, resolution may involve
        corrective actions, remedial measures, or changes to certification policies or
        procedures to prevent recurrence of similar issues.
      </p>
      <h2 className="policy-sub-title">5. Communication:</h2>
      <p>
        The complainant will be kept informed of the progress and outcome of the
        complaint investigation, including any actions taken or decisions made.
        Timely and transparent communication is essential to maintain trust and
        confidence in the complaint handling process.
      </p>
      <h2 className="policy-sub-title">6. Appeal Process:</h2>
      <p>
        If the complainant is dissatisfied with the outcome of the complaint
        resolution, they have the right to appeal the decision within a specified
        timeframe. The appeals process will provide an impartial review of the
        complaint and any subsequent actions taken, with the goal of achieving a fair
        and equitable resolution.
      </p>
      <h2 className="policy-sub-title">7. Confidentiality:</h2>
      <p>
        All complaints and related information will be treated confidentially to the
        extent permitted by law. Confidentiality safeguards the privacy of the parties
        involved and encourages open communication without fear of reprisal or
        retaliation.
      </p>
      <h2 className="policy-sub-title">8. Recordkeeping:</h2>
      <p>
        Records of complaints received, investigations conducted, and actions taken
        will be documented and maintained in accordance with record retention
        policies and applicable legal requirements.
      </p>
      <h2 className="policy-sub-title">9. Review and Updates:</h2>
      <p>
        This policy will be reviewed periodically to ensure effectiveness and alignment
        with industry standards and best practices. Updates may be made as
        necessary to enhance the efficiency and fairness of the complaint process.
      </p>
      <p>
        By adhering to this policy, we demonstrate our commitment to addressing
        complaints in a prompt, fair, and transparent manner, thereby fostering trust
        and confidence in the certification program.
      </p>
      <h2 className="policy-sub-title">Appendix A</h2>
      <h3 className="policy-sub-sub-title">Complaint Email Template Examples:</h3>
      <ol>
        <li>
          <strong>Examination Administration:</strong>
          <p>
            Subject: Complaint Regarding Examination Administration<br />
            Dear [Designated Authority],<br />
            I am writing to file a complaint regarding the administration of the recent certification examination.<br />
            [Provide brief description of the issue or concern]. I believe that [explanation of why the issue is problematic].<br />
            I kindly request a thorough investigation into this matter and appropriate actions to address the issue. Please keep me updated on the progress and outcome of the investigation.<br />
            Thank you for your attention to this matter.<br />
            Sincerely,<br />
            [Your Name]
          </p>
        </li>
        <li>
          <strong>Certification Eligibility:</strong>
          <p>
            Subject: Inquiry Regarding Certification Eligibility<br />
            Dear [Designated Authority],<br />
            I am writing to inquire about my eligibility for certification in [specify certification area]. [Provide brief explanation of your situation or query]. I have reviewed the eligibility criteria outlined on the website, and I believe that I meet all the requirements.<br />
            Could you please confirm my eligibility status and provide guidance on the next steps to proceed with the certification process?<br />
            Thank you for your assistance.<br />
            Best regards,<br />
            [Your Name]
          </p>
        </li>
        <li>
          <strong>Recertification Requirements:</strong>
          <p>
            Subject: Request for Recertification Information<br />
            Dear [Designated Authority],<br />
            I am writing to inquire about the recertification requirements for my [specify certification]. My certification is set to expire soon, and I would like to ensure that I fulfill all the necessary obligations for recertification.<br />
            Could you please provide information on the recertification process, including any continuing education requirements or recertification assessments that I need to complete?<br />
            Thank you for your prompt attention to this matter.<br />
            Best regards,<br />
            [Your Name]
          </p>
        </li>
        <li>
          <strong>Conduct of Certified Individuals:</strong>
          <p>
            Subject: Concern Regarding Conduct of Certified Individual<br />
            Dear [Designated Authority],<br />
            I am writing to express my concern regarding the conduct of a certified individual in your organization. [Provide brief description of the behavior or incident]. I believe that such behavior is not in line with the ethical standards expected of certified professionals.<br />
            I kindly request that you investigate this matter and take appropriate disciplinary action if necessary. Please keep me informed of the steps taken to address this issue.<br />
            Thank you for your attention to this matter.<br />
            Sincerely,<br />
            [Your Name]
          </p>
        </li>
        <li>
          <strong>Adherence to Certification Policies and Procedures:</strong>
          <p>
            Subject: Compliance Issue with Certification Policies<br />
            Dear [Designated Authority],<br />
            I am writing to bring to your attention a potential compliance issue with your certification policies and procedures. [Provide brief description of the issue or concern]. I believe that adherence to these policies is crucial for maintaining the integrity and credibility of your certification program.<br />
            I kindly request that you review the matter and take corrective actions as needed to ensure full compliance with your policies and procedures.<br />
            Thank you for your prompt attention to this matter.<br />
            Best regards,<br />
            [Your Name]
          </p>
        </li>
      </ol>
      <p>
        For all templates, you can include the email address <a href="mailto:support@coitb.org">support@coitb.org</a> in the email signature for inquiries and correspondence related to the certification program.
      </p>
    </div>
  );
};

export default ComplaintProcessPolicy;
