import React from 'react';
import './policyRenewalPage.css';

const RenewalPolicyPage = () => {
  return (
    <div className="renewal-policy-page">
      <h1>COITB Certification Renewal Policy</h1>
      <p>
        Effective January 1, 2024, COITB implemented the COITB Continuing Education (CE) Renewal
        Program. COITB Professional JavaScript Course and Backend MySQL Developer Course
        certifications are now valid for three years from the date the candidate is certified. The
        International Organization for Standardization (ISO), an international body that standardizes the
        procedures used by personnel certification bodies, requires that candidates have a way to renew
        the currency of their certification on a regular basis. In COITB’s case, that renewal will occur
        every three years. This change in policy is in line with the practice of other major providers of
        certifications for IT professionals. In today’s IT environment, it’s crucial for IT professionals to
        demonstrate that their knowledge and skills are current.
      </p>
      <p>
        The COITB CE Renewal Program gives eligible certification holders a range of ways to keep
        their COITB Professional JavaScript Course and Backend MySQL Developer Course
        certifications current without necessarily having to take a new exam. These certifications will be
        denoted with a “ce” designation, which means they will expire three years from the date
        certified. If the certification is not renewed within the three years, candidates will no longer be
        certified and must undergo recertification through an appropriate process determined by COITB.
      </p>
      <p>
        A grace period of 90 days is allowed for certification holders to pay any outstanding CE fee
        balance and/or upload additional continuing education units (CEUs). All CE activities must be
        completed within the three-year renewal cycle to be valid for CEUs.
      </p>
      <p>
        COITB Professional JavaScript Course and Backend MySQL Developer Course certifications
        are global certifications; therefore, this policy is applicable to all certified candidates worldwide.
        As a coalition dedicated to information technology businesses, COITB reinvests the funds it
        earns into new programs that serve the industry and the people who work in IT. This includes the
        development and ongoing management of our Continuing Education Program, which has costs
        associated with it. The funds paid for our certification exams and renewal fees contribute to the
        industry by supporting new and updated certification offerings and Continuing Education options
        for the IT workforce.
      </p>
    </div>
  );
};

export default RenewalPolicyPage;
