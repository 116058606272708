import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const InternalPersonnelPolicy = () => {
  return (
    <div className="container">
        <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="policy-title">Internal Personnel Policy</h1>
      <h2>1. Purpose</h2>
      <p>
        The purpose of this Internal Personnel Policy is to establish guidelines and expectations for
        employees of the Coalition of Inclusive Tech Businesses COITB regarding conduct, client
        centricity, responsiveness, and other key aspects of professional behavior. This policy aims to
        foster a positive work environment, promote accountability, and uphold the values and mission of
        COITB.
      </p>
      <h2>2. Conduct</h2>
      <ul>
        <li> Employees of COITB are expected to conduct themselves with professionalism, integrity, and
          respect at all times, both within the organization and in their interactions with external
          stakeholders.</li>
        <li> Discrimination, harassment, bullying, or any form of inappropriate behavior will not be tolerated
          and may result in disciplinary action, up to and including termination of employment.</li>
        <li> Employees are expected to adhere to COITB's code of conduct and ethical standards, as well as
          all applicable laws, regulations, and organizational policies.</li>
      </ul>
      <h2>3. Client Centricity</h2>
      <ul>
        <li> COITB is committed to providing excellent service and support to its clients, partners, and
          stakeholders. Employees are expected to prioritize the needs and interests of clients in all
          interactions and decision-making processes.</li>
        <li> Employees should actively listen to client feedback, anticipate client needs, and strive to exceed
          client expectations through innovative solutions and responsive communication.</li>
      </ul>
      <h2>4. Responsiveness</h2>
      <ul>
        <li> Timely and effective communication is essential for maintaining positive relationships with clients
          and colleagues. Employees are expected to respond promptly to client inquiries, requests, and
          concerns, within a reasonable timeframe.</li>
        <li> If an employee is unable to respond to a client's inquiry or request immediately, they should
          acknowledge receipt of the communication and provide an estimated timeframe for follow-up.</li>
      </ul>
      <h2>5. Confidentiality and Privacy</h2>
      <ul>
        <li> Employees are expected to maintain the confidentiality and privacy of sensitive information,
          including client data, internal discussions, and proprietary information of COITB and its clients.</li>
        <li>- Unauthorized disclosure of confidential information may result in disciplinary action, legal
          consequences, and damage to COITB's reputation and relationships with clients.</li>
      </ul>
      <h2>6. Continuous Improvement</h2>
      <ul>
        <li> COITB encourages a culture of continuous learning and improvement among its employees.
          Employees should actively seek opportunities for professional development, skill enhancement,
          and knowledge sharing.</li>
        <li> Feedback and constructive criticism are welcomed and should be used to identify areas for
          growth and development.</li>
      </ul>
      <h2>7. Compliance</h2>
      <ul>
        <li> Employees are required to comply with all applicable laws, regulations, and industry standards in
          the performance of their duties. Non-compliance may result in disciplinary action and legal
          consequences for both the employee and COITB.</li>
        <li> Employees should familiarize themselves with COITB's policies and procedures, including this
          Internal Personnel Policy, and seek clarification from management if they have any questions or
          concerns.</li>
      </ul>
      <h2>8. Reporting Violations</h2>
      <ul>
        <li> Any employee who becomes aware of a violation of this policy or any other unethical or illegal
          behavior should report it promptly to their supervisor, HR department, or designated compliance
          officer.</li>
        <li> Reports of violations will be investigated promptly and impartially, and appropriate action will be
          taken to address the situation and prevent recurrence.</li>
      </ul>
      <h2>9. Review and Revision</h2>
      <p>
        This policy will be reviewed periodically to ensure its effectiveness and relevance. Amendments
        may be made as necessary with the approval of [Designated Authority/Committee].
      </p>
      <h2>10. Communication and Training</h2>
      <p>
        This policy will be communicated to all employees of COITB, and training on its provisions,
        expectations, and consequences will be provided to new hires and periodically to all staff
        members.
      </p>
    </div>
  );
};

export default InternalPersonnelPolicy;
