import React from 'react';
import { Link } from 'react-router-dom';
import "./testingPage.css";

const TestingCriteriaAndPoliciesPage = () => {
    return (
        <div className="container">
            <h1>Testing Criteria and Policies</h1>
            <div className="policy-section">
                <Link to="/testing/accommodations" className="section-link">
                    <h2>Accommodations During an Exam</h2>
                </Link>
                <p className="subtitle">Explains the accommodations process, including how to request changes and the types of accommodations available.</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/exam-scoring" className="section-link">
                    <h2>Exam Scoring</h2>
                </Link>
                <p className="subtitle">Clarifying Exam Accommodations: Process, Requests, and Options.</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/exam-content" className="section-link">
                    <h2>Exam Content</h2>
                </Link>
                <p className="subtitle">Explains Safeguarding Exam Integrity: Prohibition on Exam Content Sharing</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/exam-feedback" className="section-link">
                    <h2>Exam Feedback</h2>
                </Link>
                <p className="subtitle">Encouraging Candidate Feedback: Formal Feedback Process</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/candidate-retesting" className="section-link">
                    <h2>Candidate Retesting</h2>
                </Link>
                <p className="subtitle">Implementing Retesting Protocol: Suspicion Resolution and Procedure</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/candidate-photograph" className="section-link">
                    <h2>Candidate Photograph</h2>
                </Link>
                <p className="subtitle">Enforcing Security Measures: Candidate Photograph Requirement</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/test-center-policy" className="section-link">
                    <h2>Test Center Reschedule and Cancellation Policies</h2>
                </Link>
                <p className="subtitle">Policy on Rescheduling and Cancellation: Procedural Requirements</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/online-exam-policy" className="section-link">
                    <h2>Online Proctored Reschedule and Cancellation Policies</h2>
                </Link>
                <p className="subtitle">Online Proctored Exam Rescheduling and Cancellation Policy</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/id-policy" className="section-link">
                    <h2>ID Policy</h2>
                </Link>
                <p className="subtitle">Candidate Identification Policy: Ensuring Exam Integrity through ID Verification</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/testing-agreement" className="section-link">
                    <h2>Agreement between COITB and Testing Candidate</h2>
                </Link>
                <p className="subtitle">Explains the Agreement between COITB and Testing Candidate </p>
            </div>
            <div className="policy-section">
                <Link to="/testing/delivery-of-exam" className="section-link">
                    <h2>Delivery of Exam</h2>
                </Link>
                <p className="subtitle">Explains the Exam Policies & Exceptions</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/retesting-policy" className="section-link">
                    <h2>Retesting Policy</h2>
                </Link>
                <p className="subtitle">Explains the Retesting Policy</p>
            </div>
            <div className="policy-section">
                <Link to="/testing/refund-policy" className="section-link">
                    <h2>Refund Policy</h2>
                </Link>
                <p className="subtitle">Explains the Refund Policy</p>
            </div>
        </div>
    );
}

export default TestingCriteriaAndPoliciesPage;
