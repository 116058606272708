import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const COITBPoliciesPage = () => {
    return (
        <div className="container">
            <h1>COITB Policies</h1>
            <div className="policy-section">
                <Link to="/policy/appeal-process-policy" className="section-link">
                    <h2>COITB Appeal Process Policy</h2>
                </Link>
                <p className="subtitle">This policy outlines the procedures for appealing decisions related to the certification program.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/internal-audit-policy" className="section-link">
                    <h2>Internal Audit Policy</h2>
                </Link>
                <p className="subtitle"> Explains guidelines and procedures for conducting internal audits within the Coalition of Inclusive Tech Businesses (COITB).</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/invalidating-a-certification-policy" className="section-link">
                    <h2>Invalidating a Certification Policy</h2>
                </Link>
                <p className="subtitle">This policy outlines the procedures and criteria for invalidating Certification when necessary to maintain the integrity and credibility of the certification program.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/candidate-certification-use-and-recertification-policy" className="section-link">
                    <h2>Candidate Certification Use Policy</h2>
                </Link>
                <p className="subtitle">This policy outlines the procedures and guidelines for the issuance and use of Certifications based on recent assessments.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/recert-policy" className="section-link">
                    <h2>Recertification Requirement Policy</h2>
                </Link>
                <p className="subtitle">This policy ensures continued proficiency and updated knowledge of your credentials.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/complaint-process-policy" className="section-link">
                    <h2>Complaint Process Policy</h2>
                </Link>
                <p className="subtitle">This policy outlines the procedures for handling complaints related to the certification program.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/advisory-board-composition-and-responsibilities" className="section-link">
                    <h2>Advisory Board Composition and Responsibilities</h2>
                </Link>
                <p className="subtitle">This policy outlines the Advisory Board Composition and Responsibilities</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/nondiscrimination-policy" className="section-link">
                    <h2>Nondiscrimination Policy</h2>
                </Link>
                <p className="subtitle">This nondiscrimination policy shows a fostering an environment that promotes equality, respect, and dignity for all.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/document-control-policy" className="section-link">
                    <h2>Document Control Policy</h2>
                </Link>
                <p className="subtitle">The purpose of this Policy is to establish guidelines and procedures for certifying document control professionals within COITB.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/internal-personnel-policy" className="section-link">
                    <h2>Internal Personnel Policy</h2>
                </Link>
                <p className="subtitle">This policy aims to foster a positive work environment, promote accountability, and uphold the values and mission of COITB.</p>
            </div>
            <div className="policy-section">
                <Link to="/policy/management-review-policy" className="section-link">
                    <h2>Management Review Policy</h2>
                </Link>
                <p className="subtitle">The purpose of this  Policy is to establish guidelines and procedures for conducting regular reviews of COITB's management systems, processes, and performance. </p>
            </div>
            <div className="policy-section">
                <Link to="/policy/outsourcing-and-collaborative-partnership-policy" className="section-link">
                    <h2>Outsourcing and Collaborative Partnership Policy</h2>
                </Link>
                <p className="subtitle">This policy aims to ensure transparency, accountability, and compliance with applicable laws, regulations, and ethical standards in all outsourcing and partnership activities. </p>
            </div>
            <div className="policy-section">
                <Link to="/policy/privacy-confidentiality-and-security-policy" className="section-link">
                    <h2>Privacy, Confidentiality, and Security Policy</h2>
                </Link>
                <p className="subtitle">This policy outlines our commitment to protecting the privacy, confidentiality, and security of candidate information in accordance with the Privacy Act of 1974 and other relevant regulations. </p>
            </div>
        </div>
    );
}

export default COITBPoliciesPage;
