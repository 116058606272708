import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const RecertificationRequirementPolicy = () => {
  return (
    <div className="container">
      <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="policy-title">Recertification Requirement Policy: COITB</h1>
      <h2>Objective</h2>
      <p>
        To ensure continued proficiency and updated competency of your credentials.
      </p>
      <h2>Validation of all Certification</h2>
      <p>
        3 Years (3-year anniversary of earned credential)
      </p>
      <h2>Certification Renewal Requirements</h2>
      <ul>
        <li><strong>Comply with Certification Rules:</strong>
          <ul>
            <li>Adhere to all certification rules, including security and confidentiality.</li>
          </ul>
        </li>
        <li><strong>Submit Evidence:</strong> Appendix A CEU Score
          <ul>
            <li>Upload 20 units of continuing education or pass the current version of the examination.</li>
            <li>Provide coursework completion certificates in the relevant certification.</li>
            <li>Submit proof of attendance of IT industry events.</li>
          </ul>
        </li>
      </ul>
      <h2>How to Apply for Recertification?</h2>
      <h3>Step 1: Register Before Expiration</h3>
      <ul>
        <li>Register to renew certification before the expiration date.</li>
      </ul>
      <p className="notice">
        NOTICE!<br/>
        If the certification expires without renewal, you will need to retake and pass the certification exam to become certified again.
      </p>
      <h3>Step 2: Provide Written Notice</h3>
      <ul>
        <li>Send a written notice to support@coitb.org at least 30 days before renewal date to ensure a swift review and approval process.</li>
      </ul>
      <h3>Step 3: Submit Documents</h3>
      <ul>
        <li>Submit CEU documents to support@coitb.org to schedule a one-on-one document review with a COITB education staff member for verification (review Appendix A for CEU acceptance).</li>
      </ul>
      <h2>Receive Email Notifications</h2>
      <p>
        COITB sends important news and reminders about certification. Ensure COITB has your correct email address by contacting support@coitb.org if unsure.
      </p>
      <h2>Personal Responsibility</h2>
      <p>
        It is the certified individual's responsibility to collect and submit the required documentation to COITB. Follow up with your employer if they are assisting with recertification.
      </p>
      <h2>Recertification Documents</h2>
      <table className="document-table">
        <thead>
          <tr>
            <th>ITEM NAME</th>
            <th>POSTED BY</th>
            <th>DATE POSTED</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><a href="/Appendix_A_CEU_Scoring.pdf" target="_blank">Appendix A CEU Scoring PDF (142.25 KB)</a></td>
            <td>Administration</td>
            <td>10/2/2023</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RecertificationRequirementPolicy;
