import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const examScoringPage = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Exam Scoring</h1>

      <h2 className="title">Classifying Scores as Indeterminate:</h2>

      <p>
        COITB continuously monitors exam performance to ensure the integrity of
        exam results. Scores may be deemed indeterminate due to discrepancies
        lacking reasonable explanations. Various factors may contribute to an
        indeterminate classification. In such instances, COITB offers candidates
        the opportunity to retake the exam at no additional cost. Indeterminate
        scores are considered invalid for certification purposes. Should a
        violation of the Candidate Conduct Policy contribute to an indeterminate
        score, COITB will enact further measures as outlined in the Candidate
        Agreement.{" "}
      </p>

      <h3 className="title">Passing Scores</h3>

      <p>
        Passing scores are determined through statistical analysis and may be
        subject to modifications. Upon completing an exam, candidates will
        receive a score report detailing their performance. Please note, COITB
        does not disclose passing rates as exam content and passing thresholds
        are periodically revised.{" "}
      </p>
    </div>
  );
};

export default examScoringPage;
