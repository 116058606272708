import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';


const NondiscriminationPolicy = () => {
  return (
    <div className="container">
         <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="policy-title">Nondiscrimination Policy</h1>
      <p>
        The Coalition of Information Technology Businesses COITB is committed to providing an inclusive
        and welcoming environment for all members, employees, volunteers, partners, and stakeholders.
        We do not discriminate on the basis of race, color, religion, sex (including pregnancy and gender
        identity), national origin, political affiliation, sexual orientation, marital status, disability, genetic
        information, age, membership in an employee organization, retaliation, parental status, military
        service, or any other status protected by applicable law.
      </p>
      <p>
        We prohibit harassment, discrimination, and retaliation of any kind against individuals or groups
        based on the aforementioned characteristics. This includes, but is not limited to, derogatory
        remarks, unwelcome advances, threats, intimidation, and other inappropriate conduct.
      </p>
      <p>
        COITB is committed to promoting diversity, equity, and inclusion within our organization and the
        broader tech industry. We strive to create a culture where all individuals are respected, valued, and
        empowered to contribute their unique perspectives and talents.
      </p>
      <p>
        Any member, employee, volunteer, partner, or stakeholder who believes they have experienced
        discrimination, harassment, or retaliation is encouraged to report the incident promptly to
        [Designated Contact Person/Department]. Reports will be promptly and thoroughly investigated,
        and appropriate action will be taken in accordance with COITB policies and applicable law.
      </p>
      <p>
        We are dedicated to upholding this nondiscrimination policy and fostering an environment that
        promotes equality, respect, and dignity for all.
      </p>
      <p className="signature">
        Hector Guerra <br />
        Chairman/CEO <br />
        COITB
      </p>
    </div>
  );
};

export default NondiscriminationPolicy;
