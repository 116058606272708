import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const deliveryofexam = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Delivery of Exam</h1>

      <h3 className="title">COITB Exam Policies:</h3>
      <ul>
        <li>
          Candidates should review all policies and requirements provided in the
          Appointment Confirmation and Appointment Reminder emails from COITB, received before the exam date.
        </li>
        <li>
          Candidates must present one (1) valid, unexpired, government-issued ID
          with a photo and signature. Refer to our Candidate ID Policy for a
          list of acceptable IDs.
        </li>
        <li>
          Minors (17 years and under) must have guardian authorization for each
          test, a form of photo ID (e.g., school ID), and the guardian must
          present a valid government-issued ID during check-in. For
          comprehensive details, please visit our Candidate ID Policy page.
        </li>
        <li>
          For OnVUE exams, candidates must take four photos of the testing area
          during check-in and be ready to conduct a room scan as part of the
          check-in procedure. This ensures the testing environment complies with
          COITB standards. Proctors may request additional room scans if
          necessary to ensure compliance with our policies.
        </li>
        <li>
          For concerns about online testing privacy, please consult our privacy
          statement.
        </li>
      </ul>

      <h3 className="title">Important Policy Reminders:</h3>
      <ul>
        <li>
          Taking photos or videos of the exam screen is strictly prohibited.
          Monitoring continues until the exam concludes. Violations, including
          capturing exam results, will lead to exam revocation and score
          invalidation.
        </li>
        <li>
          Continuously reading questions aloud is a policy breach. Occasional
          murmuring is understandable, but persistent reading aloud, despite
          warnings, will result in exam termination and a report to COITB.
        </li>
        <li>
          Altering your computer's operation, using unauthorized software, or
          compromising the exam's integrity in any way is not allowed.
        </li>
      </ul>

      <h3 className="title">Delivery Exclusions:</h3>
      <ul>
        <li>
          COITB retains the authority to exclude certain regions, countries, or
          testing centers from the COITB Certification Program.
        </li>
      </ul>

      <h3 className="title">Beta Testing:</h3>
      <ul>
        <li>
          COITB reserves the right to exclude countries or specific test centers
          from beta testing programs.
        </li>
      </ul>

      <h3 className="title">Testing Center Suspensions:</h3>
      <ul>
        <li>
          COITB may suspend testing at any location suspected of compromising
          our certification program's security or integrity.
        </li>
        <li>
          Suspended centers may be reconsidered if they agree to and comply with
          a COITB-approved corrective action plan. Conditions for reinstatement
          include passing five (5) integrity audits within a year. Failure to
          pass an audit results in indefinite closure.
        </li>
      </ul>
    </div>
  );
};

export default deliveryofexam;
