import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const AppealProcessPolicy = () => {
  return (
    <div className="container">
        <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="title">COITB Appeal Process Policy</h1>
      
      <p>
        Purpose: This policy outlines the procedures for appealing decisions related to the certification program. The appeals process provides certified individuals and candidates with a mechanism to seek review of decisions made regarding their certification status or related matters.
      </p>

      <ol>
        <li>
          <h2>Submission of Appeals:</h2>
          <p>
            Certified individuals and candidates may submit an appeal in writing to the support@coitb.org within 72 hours of receiving notification of the decision being appealed. Appeals should include detailed information regarding the grounds for appeal, relevant facts, supporting evidence, and the desired outcome.
          </p>
        </li>
        <li>
          <h2>Appeals Handling Process:</h2>
          <p>
            Upon receipt of an appeal, the Appeals Committee will initiate a review process to assess the validity of the appeal and determine the appropriate course of action. The Appeals Committee may request additional information or documentation from the appellant or other relevant parties as necessary to make an informed decision.
          </p>
        </li>
        <li>
          <h2>Timeliness of Appeals:</h2>
          <p>
            To ensure prompt resolution and minimize disruption to the appellant, appeals will be processed within a 72-hour period from the time of receipt. This expedited timeframe is necessary to mitigate any potential negative impact on the appellant's ability to secure employment or maintain employment status.
          </p>
        </li>
        <li>
          <h2>Decision-Making Authority:</h2>
          <p>
            The Appeals Committee, comprised of [Designated Members], has the authority to review appeals and make decisions based on the evidence presented and applicable certification policies and procedures. The decision of the Appeals Committee is final and binding.
          </p>
        </li>
        <li>
          <h2>Communication:</h2>
          <p>
            The appellant will be kept informed of the progress and outcome of the appeals process, including any actions taken or decisions made. Timely and transparent communication is essential to ensure fairness and accountability throughout the process.
          </p>
        </li>
        <li>
          <h2>No Staff Representation:</h2>
          <p>
            COITB staff will not, under any circumstances, speak on behalf of individuals involved in the appeals process. This policy is in place to maintain the integrity of the appeals process and prevent any perception of bias or unfairness. All communications related to appeals must be submitted directly by the appellant or their authorized representative.
          </p>
        </li>
        <li>
          <h2>Confidentiality:</h2>
          <p>
            All information and documentation submitted as part of the appeals process will be treated confidentially to the extent permitted by law. Confidentiality safeguards the privacy of the parties involved and encourages open communication without fear of reprisal or retaliation.
          </p>
        </li>
        <li>
          <h2>Recordkeeping:</h2>
          <p>
            Records of appeals received, decisions made, and any related correspondence will be documented and maintained in accordance with record retention policies and applicable legal requirements.
          </p>
        </li>
        <li>
          <h2>Review and Updates:</h2>
          <p>
            This policy will be reviewed periodically to ensure effectiveness and alignment with industry standards and best practices. Updates may be made as necessary to enhance the efficiency and fairness of the appeals process.
          </p>
        </li>
      </ol>

      <p>
        By adhering to this policy, we demonstrate our commitment to ensuring a fair and transparent appeals process that upholds the rights and interests of all parties involved.
      </p>
    </div>
  );
};

export default AppealProcessPolicy;
