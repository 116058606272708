import React from 'react';
import "./testingPage.css"; 
import { Link } from 'react-router-dom';

const testingagreement = () => {
  return (
    <div className="container">
        <Link to="/testing-policies" className="back-button">Back to Testing Policies</Link>
      <h1 className="title">Agreement between COITB and Testing Candidate</h1>

      <h2 className="title">1. Definitions</h2>

     <p>For this Agreement, the following definitions apply:</p>

      <ul>
        <li>1.1. "Candidate Information" refers to all information related to you collected by COITB or any COITB Agent, including registration details, exam results, and all submitted information for participating in any COITB Program.</li>
        <li>1.2. "COITB Agents" are testing centers, vendors, and service providers authorized by COITB to offer services related to COITB Programs.</li>
        <li>1.3. "COITB Certification" signifies the attainment of a certification status within a COITB Program, contingent upon meeting COITB's requirements and agreeing to this Agreement.</li>
        <li>1.4. "COITB Program" denotes any certification program offered by COITB.</li>
        <li>1.5. "Examination Materials" include COITB certification exams and related materials such as questions, answers, and any supplementary resources.</li>
        <li>1.6. "Marks" refers to COITB's titles, trademarks, service marks, and logos designated for use by certified individuals.</li>
      </ul>
    
<h2 className="title">2. Candidate Information</h2>

<p>COITB and its Agents may process Candidate Information for various purposes, including analysis, certification, and marketing, in line with this Agreement. This data may be shared internationally, acknowledging varying data protection laws. COITB processes personal data based on legal requirements, consent, legitimate interest, or legal obligations. You consent to COITB disclosing your certification status and related details under specified conditions.</p>

<h2 className="title">3. COITB Certification</h2>

<p>Achievement of COITB Certification is based upon passing the relevant exams and adhering to this Agreement, including a separate logo agreement for using COITB Marks. You must promptly inform COITB of any changes affecting your certification status. COITB reserves the right to modify certification requirements, which may affect your certification status.</p>

<h2 className="title">4. Testing Policies and Candidate Conduct</h2>

<p>Testing must be registered and conducted within your country of residence. Violations, including out-of-country testing without approval, compromise the integrity of COITB certifications and may result in sanctions. Unethical behaviors, such as cheating, are strictly prohibited.</p>

<h2 className="title">5. Data Forensics</h2>

<p>COITB uses data forensics to enforce certification standards. Scores may be invalidated if statistical analysis suggests irregularities.</p>

<h2 className="title">6. Retake Policy</h2>

<p>Candidates must wait 14 days before a third or subsequent exam attempt. Passing an exam prohibits retaking it without COITB's consent, except when new exam versions are released.</p>

<h2 className="title">7. Violations</h2>

<p>Violations of this Agreement may result in certification revocation and other sanctions. COITB may share your information with third parties in case of violations.</p>

<h2 className="title">8. Confidentiality of Examination Materials</h2>

<p>Examination materials are confidential and copyrighted by COITB. Unauthorized disclosure is prohibited and subject to legal action.</p>

<h2 className="title">9. Candidate Appeals</h2>

<p>Candidates may appeal sanctions within 30 days of notification, following COITB's appeals process.</p>

<h2 className="title">10. Marks</h2>

<p>Certification grants a non-transferable license to use COITB Marks under specific conditions. Unauthorized use is prohibited.</p>

<h2 className="title">11. Term and Termination</h2>

<p>This Agreement is effective upon acceptance and may be terminated by either party with notice. COITB may terminate this Agreement for breaches or certification requirement failures.</p>

<h2 className="title">12. Disclaimer; Limitation of Liability</h2>

<p>COITB makes no warranties related to certifications or this Agreement and limits its liability to the exam fees paid by the candidate.</p>

<h2 className="title">13. Indemnification</h2>

<p>Candidates agree to indemnify COITB and its Agents against any breaches of this Agreement.</p>

<h2 className="title">14. Other Provisions</h2>

<p>Includes terms on successors, amendments, waiver, severability, equitable relief, notices, and governing law, ensuring the Agreement's enforceability and compliance.</p>

    </div>
  );
};

export default testingagreement;