import React from 'react';
import './codeOfEthics.css';

const EthicsPage = () => {
  return (
    <div className="ethics-page">
      <h1>COITB Professional Code of Ethics:</h1>
      <p>All persons having obtained any COITB certification, including those taking part in COITB's Continuing Education Program ("CCEP"), and all persons seeking to obtain a COITB certification or certification program (collectively referred to herein as “Certified Persons”) must agree that they have read and will abide by the terms and conditions of this COITB Professional Code of Ethics Policy ("Ethics Policy"), prior to seeking a COITB certification, prior to participating in the CCEP, and for as long as they hold a valid COITB Certification.</p>
      <p>It is a violation of this Ethics Policy for any Certified Person to participate in any incident of cheating, breach of security, misconduct, submission of fraudulent information, or any other behavior that could compromise the integrity or confidentiality of any COITB certification examination, any COITB certification, or the COITB Continuing Education Program, as determined by COITB. It is also a violation of this Ethics Policy for any Certified Person to be engaged in any professional misconduct that negatively reflects upon themselves, an employer, and/or COITB’s Certification program, as described below. All Certified Persons shall adhere to the following:</p>
      <ul>
        <li>All information submitted for participating in and earning units from the CCEP must have been completed by the participating Certified Person.</li>
        <li>A Certified Person shall abide by all the terms and conditions set forth in the COITB Candidate Agreement.</li>
        <li>A Certified Person shall only submit continuing education units that they themselves have completed.</li>
        <li>A Certified Person shall only provide accurate and authentic information for earning continuing education units.</li>
        <li>A Certified Person shall abide by the COITB Continuing Education Audit Policies as set forth by COITB from time to time.</li>
        <li>A Certified Person shall offer and provide professional services with integrity.</li>
        <li>A Certified Person shall perform professional services in a manner that is fair and reasonable to clients, principals, partners, and employers, and shall disclose conflicts of interest in providing such services.</li>
        <li>A Certified Person shall not disclose any confidential client information without the specific consent of the client.</li>
        <li>A Certified Person will always conduct themselves in a manner that enhances the image of the IT profession.</li>
        <li>A Certified Person shall provide services to clients competently and maintain the necessary knowledge and skill to continue to do so in those areas in which they are certified.</li>
        <li>A Certified Person shall not solicit clients through false or misleading communications or advertisements.</li>
        <li>In the course of performing professional activities, a Certified Person shall not engage in conduct involving dishonesty, fraud, deceit, or misrepresentation, or knowingly make a false or misleading statement to a client, employer, employee, professional colleague, governmental or other regulatory body or official, or any other person or entity.</li>
        <li>A Certified Person shall not engage in activities involving unauthorized breach of IT systems, including but not limited to hacking, uploading malicious code, or any other actions that may affect the integrity of said IT systems.</li>
      </ul>
      <h2>Procedural Requirements for Code of Ethics for Certified Persons</h2>
      <h3>Notice of Violations and Sanctions</h3>
      <p>If it is determined that any Certified Person has violated this Ethics Policy, COITB will send such Certified Person a written notice of violations and applicable sanctions and a copy of COITB's Appeals Policy and Process.</p>
      <h3>Remedies for Violating the Ethics Policy</h3>
      <p>Any Certified Person determined by COITB to have violated this Ethics Policy shall, if determined necessary or appropriate by COITB based upon the seriousness of the incident or violation:</p>
      <ul>
        <li>(a) be denied and/or decertified in a COITB certification(s)</li>
        <li>(b) have all other COITB certifications previously granted to such candidate revoked;</li>
        <li>(c) not be eligible to register and/or schedule any COITB certification examination, or receive any COITB certification, for a minimum period of twelve (12) calendar months from the date of such determination;</li>
        <li>(d) have the current continuing education program cycle suspended and/or not be eligible to pursue any COITB certification for a minimum period of twelve (12) calendar months from the date of such determination; and/or</li>
        <li>(e) be subject to any and all other appropriate action, including legal remedies, that COITB deems necessary or appropriate to enforce COITB's Ethics Policy.</li>
      </ul>
      <h3>Appeals Process</h3>
      <p>After a Certified Person has received a written notice of violations and applicable sanctions from COITB, such Certified Person will have thirty (30) calendar days to file a written request for appeal pursuant to COITB's Appeals Policy and Process.</p>
    </div>
  );
};

export default EthicsPage;
