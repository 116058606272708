import React from 'react';
import "./testingPage.css"; 
import { Link } from 'react-router-dom';

const testcenterpolicy = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">Back to Testing Policies</Link>
      <h1 className="title">Test Center Reschedule and Cancellation Policies</h1>

      <h2 className="title">Reschedule Policy:</h2>

      <p>Candidates must reschedule their exam appointments at least one full business day (24 hours) in advance of the scheduled time, either through the candidate website or by contacting the call center. Failing to reschedule at least 24 hours before your appointment or not showing up will result in the loss of your exam fee.</p>
      
      <h3 className="title">Cancellation Policy:</h3>

      <p>Candidates must cancel their exam appointments at least one full business day (24 hours) in advance of the scheduled time, either through the candidate website or by contacting the call center. Failing to cancel at least 24 hours before your appointment or not showing up will result in the loss of your exam fee. Note that exams scheduled with certain accommodations may require up to three days to reschedule.</p>

    </div>
  );
};

export default testcenterpolicy;