import React from "react";
import "./policiesPage.css";
import { Link } from "react-router-dom";

const CertificateInvalidationPolicy = () => {
  return (
    <div className="container">
      <Link to="/policies-page" className="back-button">
        Back to Policies
      </Link>
      <h1 className="title">Certification Invalidation Policy</h1>

      <ol>
        <li>
          <h2>Purpose</h2>
          <p>
            This policy outlines the procedures and criteria for invalidating
            Certifications when necessary to maintain the integrity and
            credibility of the certification program. Certification invalidation
            may occur in instances of fraud, misconduct, non-compliance with
            certification requirements, or other circumstances that compromise
            the validity of the certification.
          </p>
        </li>
        <li>
          <h2>Grounds for Invalidation</h2>
          <ul>
            <li>
              <strong>Fraudulent Activity:</strong> Certifications obtained
              through fraudulent means, including but not limited to cheating on
              examinations, misrepresentation of qualifications, or submission
              of falsified documentation.
            </li>
            <li>
              <strong>Misconduct:</strong> Instances of unethical behavior,
              professional misconduct, or violations of the certification code
              of conduct that undermine the integrity of the certification
              program.
            </li>
            <li>
              <strong>Non-Compliance:</strong> Failure to comply with
              certification requirements, such as recertification obligations,
              continuing education requirements, or adherence to certification
              policies and procedures.
            </li>
            <li>
              <strong>Legal or Regulatory Requirements:</strong> Invalidation
              may be necessary to comply with legal or regulatory mandates,
              court orders, or other official directives.
            </li>
            <li>
              <strong>Other Justifiable Reasons:</strong> Certification
              invalidation may be warranted in exceptional cases where there is
              clear evidence of wrongdoing or where maintaining the validity of
              the Certification poses a risk to public safety or the reputation of
              the certification program.
            </li>
          </ul>
        </li>
        <li>
          <h2>Investigation Process</h2>
          <p>
            Before invalidating a Certification, a thorough investigation will
            be conducted to gather evidence and assess the validity of the
            allegations. The investigation may involve reviewing documentation,
            conducting interviews, and consulting relevant stakeholders as
            necessary to determine the appropriate course of action.
          </p>
        </li>
        <li>
          <h2>Decision-Making Authority</h2>
          <p>
            The decision to invalidate a Certification will be made by
            [Designated Authority], who has the authority to assess the
            evidence, consider mitigating factors, and make an informed decision
            based on the findings of the investigation.
          </p>
        </li>
        <li>
          <h2>Notification</h2>
          <p>
            Once a decision to invalidate a Certification has been reached, the
            Certification holder will be notified in writing of the reasons for
            invalidation, the effective date of invalidation, and any applicable
            appeal procedures.
          </p>
        </li>
        <li>
          <h2>Appeal Process</h2>
          <p>
            Certification holders have the right to appeal the decision to
            invalidate their Certification within a specified timeframe. The
            appeals process will provide an opportunity for the Certification
            holder to present additional evidence or arguments in support of
            their case.
          </p>
        </li>
        <li>
          <h2>Public Disclosure</h2>
          <p>
            Details of invalidated Certification may be disclosed publicly, as
            deemed appropriate and in accordance with applicable privacy laws
            and regulations. Public disclosure serves to maintain transparency
            and accountability within the certification program and may serve as
            a deterrent to future misconduct.
          </p>
        </li>
        <li>
          <h2>Recordkeeping</h2>
          <p>
            Records of invalidated Certifications, including supporting
            documentation and correspondence, will be maintained in accordance
            with record retention policies and applicable legal requirements.
          </p>
        </li>
        <li>
          <h2>Review and Updates</h2>
          <p>
            This policy will be reviewed periodically to ensure alignment with
            industry standards, legal requirements, and best practices. Updates
            may be made as necessary to enhance the effectiveness and fairness
            of the Certification invalidation process.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default CertificateInvalidationPolicy;
