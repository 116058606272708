import React, { useState } from "react";
import { Modal, Form, FloatingLabel, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import "./paymentOption.css";
// import axios from 'axios';
import CourseCertIdStep from "./CourseCertIdStep";
import PaymentForm from "./PaymentForm";
import ProctorFormStep from "./ProctorFormStep";
// import { payment_info } from '../../services/cardPointePaymentApi';

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string().required("Card number is required."),
  cvv2: Yup.number().required("3-4 digit cvv is required"),
  expiryMonth: Yup.number().required("Expiration month is required"),
  expiryYear: Yup.number().required("Expiration year is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  region: Yup.string().required("State is required"),
  postal: Yup.string().required("Zip code is required"),
});

const PaymentStepper = ({ show, handleClose, certification }) => {
  const [visible, setVisible] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hasClickedPoliciesLink, setHasClickedPoliciesLink] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirmPayment = () => setShowConfirmation(true);
  const handleCancelConfirmation = () => setShowConfirmation(false);
  const handleConfirmPurchase = () => {
    // Add your payment confirmation logic here
    console.log("Payment Confirmed!");
    setShowConfirmation(false);
  };

  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    courseCertId: "",
    cardNumber: "",
    expiryMonth: 0,
    expiryYear: 0,
    cvv2: '',
    address: "",
    city: "",
    region: "",
    postal: "",
  });
  const [isApproved, setIsApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = async (values) => {
    setStep(step + 1);
    // if (step === 1) {
    //     setFormValues(values);
    //     setIsLoading(true);
    //     try {
    //         const res = await payment_info(values);
    //         if (res && res.resptext === 'Approval') {
    //             setIsApproved(true);
    //             setShowPaymentForm(true);
    //         } else {
    //             setIsApproved(false);
    //         }
    //     } catch (error) {
    //         console.error('Error: ', error.message);
    //         setIsApproved(false);
    //     } finally {
    //         setIsLoading(false);
    //         setStep(step + 1);
    //     }
    // } else {
    //     setStep(step + 1);
    // }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormValues((userInput) => ({
      ...userInput,
      [name]: value,
    }));
  };

  // const handleSubmit = async (values) => {
  //     try {
  //         const res = await axios.post('api-route-here', values);
  //         console.log('API Response', res.data);
  //         setFormValues(values);
  //         // setIsSubmitting(true) ToDo add logic for react spinner while waiting for server api response
  //     } catch (error) {
  //         console.error('Error:', error);
  //     }
  //     console.log('Final form values: ', values);
  // };

  const handleCloseModal = () => {
    setStep(1); // Reset the step
    setFormValues({
      // Reset the form values
      courseCertId: "",
      cardNumber: "",
      expiryMonth: 0,
      expiryYear: 0,
      postal: "",
      cvv2: "",
      address: "",
      city: "",
      region: "",
    });
    setIsApproved(false); // Reset approval status
    setIsLoading(false); // Reset loading status
    handleClose(); // Close the modal
  };

  const handlePoliciesLinkClick = () => {
    setHasClickedPoliciesLink(true);
  };

  const handleCheckboxChange = () => {
    if (hasClickedPoliciesLink) {
      setIsChecked(!isChecked);
    } else {
      alert("Please read the testing policies before agreeing.");
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Body>
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onPrev={handlePrev}
        >
          {({ handleSubmit }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleConfirmPayment();
              }}
            >
              <div className="payment-option">
                <div className="rectangle">
                  <button className="close-button" onClick={handleClose}>
                    &times;
                  </button>
                  <div className="content">
                    <div className="left-side">
                      <div className="payment-header">
                        <div className="price">$999.99</div>
                        <div className="title">
                          JavaScript Professional Developer Online Exam
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="invoice-details">
                        <div className="invoice-header">
                          Certification Exam Invoice
                        </div>
                        <p className="invoice-text">
                          Review your order and fill data to complete payment.
                          ALL SALES ARE FINAL
                        </p>
                        <div className="details-header">Details</div>
                        <p className="details-text">
                          The purchase of this exam is to gain certification as a
                          professional JavaScript developer.
                        </p>
                        <div className="invoice-items-header">Invoice items</div>
                        <div className="invoice-item-container">
                          <div className="invoice-item">
                            <span className="item-description">
                              JavaScript Professional Developer Online Exam
                            </span>
                            <span className="item-price">$999.99</span>
                          </div>
                        </div>
                        <div className="invoice-summary">
                          <div className="invoice-summary-item">
                            <span className="summary-label">Subtotal</span>
                            <span className="summary-value">$999.99</span>
                          </div>
                          <hr className="divider" />
                          <div className="invoice-summary-item">
                            <span className="summary-label">Total</span>
                            <span className="summary-value">$999.99</span>
                          </div>
                        </div>
                        <p className="final-text">
                          All purchases made are Final
                        </p>
                      </div>
                    </div>
                    <hr className="vertical-divider" />
                    <div className="right-side">
                      <div className="payment-header">Payment Details</div>
                      <div className="payment-details">
                        <div className="input-group">
                          <label htmlFor="name">Your Name</label>
                          <input
                            type="text"
                            id="name"
                            placeholder="Your Name"
                          />
                        </div>
                        <div className="input-group">
                          <FloatingLabel
                            controlId="cardNumber"
                            label="Card number"
                          >
                            <Form.Control
                              type="text"
                              name="cardNumber"
                              value={formValues.cardNumber}
                              onChange={handleOnChange}
                              placeholder="Card number"
                            />
                          </FloatingLabel>
                        </div>
                        <div className="input-row">
                          <div className="input-group">
                            <FloatingLabel
                              controlId="expiryMonth"
                              label="Month"
                            >
                              <Form.Control
                                type="text"
                                name="expiryMonth"
                                value={formValues.expiryMonth}
                                onChange={handleOnChange}
                                placeholder="MM"
                              />
                            </FloatingLabel>
                          </div>
                          <div className="input-group">
                            <FloatingLabel controlId="expiryYear" label="Year">
                              <Form.Control
                                type="text"
                                name="expiryYear"
                                value={formValues.expiryYear}
                                onChange={handleOnChange}
                                placeholder="YYYY"
                              />
                            </FloatingLabel>
                          </div>
                          <div className="input-group">
                            <FloatingLabel controlId="cvv2" label="CVV">
                              <Form.Control
                                type="text"
                                name="cvv2"
                                value={formValues.cvv2}
                                onChange={handleOnChange}
                                placeholder="CVV"
                              />
                            </FloatingLabel>
                          </div>
                          <div className="input-group">
                            <FloatingLabel controlId="postal" label="Zip Code">
                              <Form.Control
                                type="text"
                                name="postal"
                                value={formValues.postal}
                                onChange={handleOnChange}
                                placeholder="Zip Code"
                              />
                            </FloatingLabel>
                          </div>
                        </div>
                        <div className="terms">
                          <input
                            type="checkbox"
                            id="terms"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="terms">
                            I agree with all{" "}
                            <a
                              href="/testing-policies"
                              target="_blank"
                              onClick={handlePoliciesLinkClick}
                            >
                              terms and conditions and testing policies
                            </a>
                          </label>
                        </div>
                        <button
                          disabled={!isChecked}
                          className="confirm-button"
                          onClick={() => console.log("clicked")}
                        >
                          Confirm Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal show={showConfirmation} onHide={handleCancelConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to confirm this purchase?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelConfirmation}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmPurchase}
            style={{ backgroundColor: "#055487", borderColor: "#055487" }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Modal>
  );
};

export default PaymentStepper;
