import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const candidateRetestingPage = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Candidate Retesting</h1>

      <p>
        COITB reserves the right to require retesting if there's suspicion
        regarding the legitimacy or accuracy of test scores. Retesting will
        occur at a COITB-approved location or online, scheduled at a mutually
        convenient time.
      </p>
    </div>
  );
};

export default candidateRetestingPage;
