import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="container">
        <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="title">Privacy, Confidentiality, and Security Policy</h1>
      
      <p>
        Purpose: This policy outlines our commitment to protecting the privacy, confidentiality, and security of
        candidate information in accordance with the Privacy Act of 1974 and other relevant regulations.
        We recognize the importance of safeguarding personal information and maintaining the trust and
        confidence of our candidates and stakeholders.
      </p>

      <ol>
        <li>
          <h2>Collection and Use of Personal Information:</h2>
          <p>
            We collect and use personal information from candidates solely for the purpose of administering
            the certification program, evaluating candidate eligibility, and maintaining certification records.
            Personal information will only be used for lawful purposes and with the consent of the individual.
          </p>
        </li>
        <li>
          <h2>Protection of Personally Identifiable Information (PII):</h2>
          <p>
            We are committed to protecting the confidentiality of candidate information, including but not
            limited to names, addresses, contact information, and examination results. Access to candidate PII
            is restricted to authorized personnel who require access for legitimate business purposes.
          </p>
        </li>
        <li>
          <h2>Compliance with Privacy Act of 1974:</h2>
          <p>
            We adhere to the provisions of the Privacy Act of 1974, which regulates the collection, use, and
            dissemination of personal information by federal agencies. This includes implementing appropriate
            administrative, technical, and physical safeguards to protect candidate information from
            unauthorized access, disclosure, alteration, or destruction.
          </p>
        </li>
        <li>
          <h2>Confidentiality:</h2>
          <p>
            We value the confidentiality of candidate information and treat all personal data with the utmost
            sensitivity and discretion. Confidential information will not be disclosed to third parties except as
            required by law or with the explicit consent of the candidate.
          </p>
        </li>
        <li>
          <h2>Security Measures:</h2>
          <p>
            We employ comprehensive security measures to safeguard candidate information against
            unauthorized access, misuse, or loss. These measures include encryption, firewalls, access
            controls, regular security audits, and employee training on data security best practices.
          </p>
        </li>
        <li>
          <h2>Data Retention and Disposal:</h2>
          <p>
            Candidate information will be retained only for as long as necessary to fulfill the purposes for which
            it was collected or as required by law. When candidate information is no longer needed, it will be
            securely disposed of in accordance with established data retention and disposal procedures.
          </p>
        </li>
        <li>
          <h2>Transparency and Accountability:</h2>
          <p>
            We are committed to being transparent about our data handling practices and accountable for our
            compliance with privacy, confidentiality, and security requirements. Candidates have the right to
            request access to their personal information and to seek correction of any inaccuracies.
          </p>
        </li>
        <li>
          <h2>Review and Updates:</h2>
          <p>
            This policy will be reviewed periodically to ensure alignment with applicable laws, regulations, and
            industry best practices. Updates may be made as necessary to enhance the effectiveness and
            comprehensiveness of our privacy, confidentiality, and security measures.
          </p>
        </li>
      </ol>

      <p>
        By adhering to this policy, we demonstrate our commitment to protecting the privacy, confidentiality, and security of candidate information and maintaining the trust and confidence of our candidates and stakeholders.
      </p>

      <h1 className="title">Leakage of Information and Protection Quarterly Penetration Testing Policy</h1>

      <p>
        Purpose: This policy outlines the procedures for conducting quarterly penetration testing of the COITB
        database and web application to proactively identify and mitigate vulnerabilities that could lead to
        unauthorized access or data breaches. This testing is conducted as part of a comprehensive
        cybersecurity audit under the Cybersecurity Maturity Model Certification (CMMC) framework to
        ensure compliance with security standards and protect user data.
      </p>

      <ol>
        <li>
          <h2>Frequency of Testing:</h2>
          <p>
            Penetration testing of the COITB database and web application will be conducted on a quarterly
            basis to assess the effectiveness of security controls, identify vulnerabilities, and prevent
            unauthorized access to user data. Testing will be scheduled at regular intervals to maintain the
            security posture of our systems and address emerging threats.
          </p>
        </li>
        <li>
          <h2>Scope of Testing:</h2>
          <p>
            The penetration testing will encompass all components of the COITB database and web
            application, including but not limited to databases, servers, applications, and network
            infrastructure. Testing will simulate real-world attack scenarios to evaluate the resilience of our
            systems and identify potential weaknesses.
          </p>
        </li>
        <li>
          <h2>Methodology:</h2>
          <p>
            Penetration testing will be conducted using industry-standard methodologies and tools by qualified
            cybersecurity professionals with expertise in ethical hacking and vulnerability assessment. Testing
            techniques may include network scanning, application testing, social engineering, and exploitation
            of known vulnerabilities.
          </p>
        </li>
        <li>
          <h2>Vulnerability Remediation:</h2>
          <p>
            Upon completion of the penetration testing, identified vulnerabilities will be prioritized based on
            severity and risk impact. Remediation plans will be developed to address critical and high-risk
            vulnerabilities in a timely manner, with appropriate measures implemented to mitigate the
            identified risks.
          </p>
        </li>
        <li>
          <h2>Reporting and Documentation:</h2>
          <p>
            A comprehensive report detailing the findings of the penetration testing will be prepared,
            documenting identified vulnerabilities, their severity levels, and recommended remediation
            actions. The report will be reviewed by the relevant stakeholders, including IT security personnel
            and senior management, to ensure timely resolution of identified issues.
          </p>
        </li>
        <li>
          <h2>Compliance with CMMC:</h2>
          <p>
            The quarterly penetration testing of the COITB database and web application is conducted as part
            of our commitment to achieving and maintaining compliance with the Cybersecurity Maturity
            Model Certification (CMMC) framework. This certification demonstrates our dedication to
            protecting sensitive information and maintaining a robust cybersecurity posture.
          </p>
        </li>
        <li>
          <h2>Continuous Improvement:</h2>
          <p>
            Feedback from penetration testing exercises will be used to enhance our security posture and
            strengthen our defenses against emerging threats. Lessons learned from testing results will inform
            future security investments and initiatives aimed at continuously improving our cybersecurity
            resilience.
          </p>
        </li>
      </ol>

      <p>
        By conducting quarterly penetration testing of the COITB database and web application, we
        demonstrate our commitment to proactively identifying and addressing security vulnerabilities to
        prevent unauthorized access to user data and protect the integrity and confidentiality of our
        systems.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
