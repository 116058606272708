import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const SMEPolicy = () => {
  return (
    <div className="container">
        <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="policy-title">Advisory Board Composition and Responsibilities</h1>
      <h2 className="policy-sub-title">Subject Matter Expert (SME) for COITB Projects</h2>
      <p>
        At COITB, we recognize the indispensable role of Subject Matter Experts (SMEs) in the development
        and ongoing refinement of our certification examinations. The insights and expertise of IT
        professionals actively engaged in the field are pivotal to our mission of crafting examinations that
        reflect the most current and relevant industry standards.
      </p>
      <p>
        Collaboration with SMEs is fundamental to the creation of high-quality content that accurately
        represents the necessary level of expertise. These experts, in concert with COITB personnel, are
        instrumental in the generation or evaluation of examination content that encompasses the
        technologies, skills, and best practices essential for today's IT professionals. We are committed to
        acknowledging the significant contributions of our SMEs, ensuring they receive appropriate
        recognition and incentives for their invaluable support.
      </p>
      <h2 className="policy-sub-title">Requirements for SME Support</h2>
      <p>
        Eligibility for participating in exam design with COITB necessitates practical
        experience with the relevant technology and online web tools, tailored to the specific demands of
        the development workforce. While it is advantageous for Subject Matter Experts (SMEs) to hold
        COITB certifications, this criterion is not mandatory for application.
      </p>
      <p>
        Prospective collaborators should be prepared to dedicate themselves to full-time engagement,
        encompassing several hours either weekly or during proposed contract hours virtually. Must be
        accessible to virtual environments in a non-disruption environment.
      </p>
      <p>
        Collaborators are required to agree to the COITB Non-Disclosure Agreement as part of their
        commitment to maintain the confidentiality of examination curriculum contents.
      </p>
      <p>
        While COITB values the enthusiasm and interest of professionals from the training sector, it is
        important to note that trainers, authors, or individuals who might gain financial advantage or
        substantial benefit from insider knowledge of COITB certification exam content are deemed
        ineligible for the SME support roles or contracts with COITB.
      </p>
      <p>
        It is also crucial to acknowledge that submission of an application does not guarantee
        qualification. COITB employs a rigorous selection process to ensure that only applicants who meet
        the specific criteria and requirements of our initiative are chosen to participate.
      </p>
      <h2 className="policy-sub-title">Responsibility and Background of an SME with COITB</h2>
      <p>
        COITB engages Subject Matter Experts (SMEs) to leverage their specialized industry knowledge and
        proficiency in distinct technical domains. Partnered with Era Solutions LLC, a National DOL
        Registered Apprenticeship Program and Software Development and training company, which
        provides upskilling to thousands of military professionals with employment support from
        government contracting firms and non-government supporting businesses, the company consults
        for COITB for training and development.
      </p>
      <p>
        COITB expects experts to bring a depth of understanding and skill reflective of the roles targeted by
        COITB certification initiatives, thereby guiding the creation of accurate and credible examination
        content. Furthermore, SMEs are tasked with acquiring the skills necessary for developing
        examination material, evaluating content, and appraising the quality and challenge level of our
        certification exams.
      </p>
      <p>
        The roles and responsibilities assigned to SMEs might include, but are not limited to, the ability to:
      </p>
      <ul>
        <li>Demonstrate comprehensive, practical, and hands-on knowledge within their field of specialization.</li>
        <li>Grasp and apply the terminology, policies, concepts, and best practices relevant to their area of expertise.</li>
        <li>Offer insights that aid in the formulation of examination objectives and the delineation of performance criteria essential for specific professional roles or target demographics.</li>
        <li>Craft examination questions with proficiency and precision.</li>
        <li>Familiarized with learning management systems (LMS) and AI web-based text-to-speech technology tools.</li>
        <li>Assess and determine the difficulty levels of examination questions.</li>
        <li>Conduct meticulous reviews of examination content for factual accuracy.</li>
        <li>Ensure the quality of test items, verifying their compliance with established content standards.</li>
        <li>Render decisions that help set performance benchmarks.</li>
        <li>Take an active and constructive role in examination development.</li>
        <li>Exhibit excellent teamwork capabilities, effectively collaborating with other experts in the industry.</li>
        <li>Be able to attend quarterly meetings to address future current and future initiatives related to credentialing.</li>
      </ul>
      <p>
        COITB values the contribution of SMEs who are committed to fostering a collaborative environment
        and contributing to the development of examinations that accurately reflect the demands and
        expectations of the IT industry.
      </p>
    </div>
  );
};

export default SMEPolicy;
