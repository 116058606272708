import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, Form, FloatingLabel } from 'react-bootstrap';
import axios from 'axios';

const validationSchema = Yup.object().shape({
    courseCertId: Yup.string().required('Course ID is required.')
});

const CourseCertIdStep = ({initialValues, onNext, onChange}) => {
    const [isCourseCertIdValid, setIsCourseCertIdValid] = useState(false);

    // useEffect(() => {
    //     if(initialValues.courseCertId) {
    //         axios.get(`eraexamapi.azurewebsites.net/sub-route-here/${initialValues.courseCertId}`)
    //         .then(res => {
    //             setIsCourseCertIdValid(res.data.exists);
    //         })
    //         .catch(error => {
    //             console.error('Error:', error)
    //         });
    //     }
    // },[initialValues.courseCertId]);

    // const handleSubmit = (values, { setSubmitting }) => {
    //     if(isCourseCertIdValid) {
    //         onSubmit(values);
    //     } else {
    //         alert('Course Cert ID provided is not valid');
    //     };
    //     setSubmitting(false)
    // };

  return (
    <Modal.Body>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onNext}
        >
            <Form noValidate onSubmit={onNext}>
                    <FloatingLabel controlId='courseCertId' label='Course Certification ID:' className='mb-3'>
                        <Form.Control type="text" name="courseCertId" value={initialValues.courseCertId} onChange={onChange} placeholder='#0584' className="form-control" />
                    </FloatingLabel>
            </Form>
        </Formik>
    </Modal.Body>
  )
}

export default CourseCertIdStep
