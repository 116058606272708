import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

export default function RefundPolicy() {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Refund Policy:</h1>
      <ol>
        <li>
          <h2>Refund Policy</h2>
          <p>
            Candidates who wish to cancel their exam registration must do so
            within 24 hours of purchase by contacting support@coitb.org.
            Candidates may not cancel their registration after the 24-hour
            period has passed and/or if they have already started or taken the
            exam.
          </p>
        </li>
        <li>
          <h2>Refund Process</h2>
          <p>
            To request a refund, candidates must provide their full name and
            email address used to register for the exam via email to
            support@coitb.org. Refunds will be processed within 5-7 business days.
          </p>
        </li>
      </ol>
    </div>
  );
}
