import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { NewNav } from "./components/navbar/NewNav.jsx";
import { NavbarComponent } from "./components/navbar/Navbar.jsx";
import { Footer } from "./components/footer/Footer.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NewNav />
    <App />
    <Footer />
  </React.StrictMode>
);
