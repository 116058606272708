import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const AccommodationsPage = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Accommodations During an Exam</h1>

      <p>
        COITB is dedicated to adhering to applicable laws to accommodate
        individuals with disabilities. Accommodation requests are managed
        through our organization or any future collaborative partnerships, this
        will be disclosed on our website. For comprehensive details, please
        refer to the support@coitb.org.
      </p>

      <h2 className="title">English as a Second Language Accommodation</h2>

      <p>
        COITB offers a 30-minute extension for exams conducted in English within
        non-English speaking nations, where a localized exam version is
        unavailable. Given the universal availability of our exams in English,
        this accommodation does not apply within English-speaking countries.
        Eligible candidates registering for exams with COITB will receive
        the ESL accommodation automatically. It is noteworthy that candidates in
        Quebec, Canada, eligible for ESL must contact support@coitb.org directly to
        receive this accommodation, as it is not granted automatically.
      </p>
    </div>
  );
};

export default AccommodationsPage;
