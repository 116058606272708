import styles from "./PartnerCard.module.css";
export const PartnerCard = ({ partner }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <img src={partner.logo} alt={partner.name} />
      </div>
      <div className={styles.cardBody}>
        <a href={`${partner.link}`} className={styles.partnerLink} target="_blank" rel="noopener noreferrer">
          <h2>{partner.name}</h2>
        </a>
        <p>{partner.description}</p>
      </div>
    </div>
  );
};
