import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const SpecialAccommodationPolicy = () => {
  return (
    <div className="container">
      <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="policy-title">Coalition of Information Technology Businesses COITB Special Accommodation Policy</h1>
      <h2>Purpose</h2>
      <p>
        The Coalition of Information Technology Businesses COITB is committed to providing fair and impartial accommodations to candidates who are differently abled, or have physical, visual, speech, or hearing impairments.
      </p>
      <p>
        Any individual with a physical, physiological, sensory, cognitive, or developmental impairment is categorized as a candidate with disabilities. COITB adheres to the Americans with Disabilities Act (ADA, 1991) and strives to accommodate reasonable requests by candidates with disabilities who find it challenging to attempt our certification exams within the prescribed exam duration. The special accommodation request is evaluated based on the candidate’s accommodation request, nature of disability, and practicality of the request. The special accommodation request form must be filled in and signed by a legally approved professional, expert, or practitioner to support the candidate’s special accommodation request.
      </p>
      <p>
        NOTE: Information provided to COITB will be held strictly confidential and will not be released without the candidate’s permission.
      </p>
      <h2>Timeline for Request</h2>
      <p>
        COITB requires advance notification of requests for accommodation as well as a reasonable amount of time to review and implement such requests. Candidates are required to submit their special accommodation requests to the Coalition of Information Technology Businesses at least 20 days prior to registering for the exam. COITB will respond with its decision within 7 days and provide the details of online login instructions to accommodate the candidate’s special needs.
      </p>
      <p>
        Candidates seeking to take the exam will require basic knowledge of the English language to read, comprehend, and respond to examination questions and converse with the proctor.
      </p>
      <h2>Accommodations</h2>
      <p>Accommodations may include:</p>
      <ul>
        <li>A separate testing room</li>
        <li>A scribe</li>
      </ul>
      <p>
        Test accommodations are individualized and considered on a case-by-case basis. Consequently, no single type of test accommodation would necessarily be appropriate for all individuals with disabilities.
      </p>
      <h2>How to Apply</h2>
      <p>Accommodation can be requested online using the Special Accommodation Request Form.</p>
      <p>
        Relevant documents need to be uploaded. After uploading the necessary documents, click the “Submit” button at the bottom of the page. A system-generated email will be sent to the registered email acknowledging the receipt of the request.
      </p>
      <p>
        Please allow 7 business days for the COITB team to review documents submitted. After review of documents, a notification email will be sent to the registered email ID regarding the determination of the request.
      </p>
      <p>
        After receiving the determination notification, the candidate can proceed to register for the exam.
      </p>
      <h2>Register and Schedule for the Exam</h2>
      <p>
        A candidate can register by following these steps:
      </p>
      <ol>
        <li>Send an email to proctor@coitb.org to register for the exam.</li>
        <li>Attach a copy of your resume, and a scanned copy of an identification document, such as Driver’s License or Government-issued ID Card.</li>
        <li>Scanned documents should be attached with the email.</li>
        <li>Remit the prescribed payment as a non-refundable Exam Registration Fee.</li>
        <li>COITB will verify the information submitted in the application.</li>
        <li>If the application is approved, a confirmation email to take the COITB exam, including the link for the exam, will be sent to the candidates.</li>
        <li>Visit the Coalition of Information Technology Businesses Exam Booking platform at [link] to book a convenient month and date to take up the exam.</li>
        <li>Take the exam by visiting [exam link] and download the Safe Exam Browser (SEB) tool to enter the proctoring platform.</li>
      </ol>
      <h2>Request for a Scribe</h2>
      <p>
        Generally, candidates who have an impairment that restricts their ability to hand-write, type, or maintain the posture required for writing, or those who, because of an impairment, may require a scribe. The candidate must fill and attach the Special Accommodation Request form as a scanned document in the email request to COITB.
      </p>
      <p>
        The selected scribe can have an educational background related to any field (Except cybersecurity) and can also opt to have a practice session when working with the candidate to assess the candidate’s needs and preferences. The scribe must be introduced to the Exam/Proctor Coordinator before scheduling the exam. The Exam/Proctor Coordinator would verify the identity of the scribe along with the candidate before the exam starts. COITB exam would be carried out at a physical location where a COITB proctor would be present, apart from the candidate and the scribe, in a test room.
      </p>
      <h2>During the Examination</h2>
      <ul>
        <li>Be ready at least 15 minutes before the start of the exam.</li>
        <li>The room should be quiet and equipped with suitable furniture.</li>
        <li>Unless also assigned to the role of reader, proctor, or scribe, individuals should not read out exam questions.</li>
        <li>If a scribe is present, he/she must neither give factual help nor offer any suggestions.</li>
        <li>The scribe must key in the answers as dictated.</li>
        <li>The candidate is in charge of the exam; therefore, the scribe should speak only when spoken to.</li>
        <li>Upon the candidate’s request, read back what has been recorded and change the recorded material if requested by the candidate.</li>
      </ul>
    </div>
  );
};

export default SpecialAccommodationPolicy;
