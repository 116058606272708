import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const InternalAuditPolicy = () => {
  return (
    <div className="container">
      <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="title">Internal Audit Policy</h1>
      
      <ol>
        <li>
          <h2>Purpose</h2>
          <p>
            The purpose of this Internal Audit Policy is to establish guidelines and procedures for conducting
            internal audits within the Coalition of Inclusive Tech Businesses COITB. Internal audits are
            essential for assessing the effectiveness of our internal controls, risk management processes, and
            compliance with applicable laws and regulations.
          </p>
        </li>
        <li>
          <h2>Scope</h2>
          <p>
            This policy applies to all departments, programs, and activities within COITB. It covers the planning,
            execution, reporting, and follow-up of internal audit activities.
          </p>
        </li>
        <li>
          <h2>Objectives</h2>
          <ul>
            <li>To provide independent and objective assurance on the adequacy and effectiveness of COITB's governance, risk management, and internal control processes.</li>
            <li>To identify areas of improvement and recommend corrective actions to enhance organizational performance and compliance.</li>
            <li>To promote transparency, accountability, and integrity in COITB's operations.</li>
          </ul>
        </li>
        <li>
          <h2>Independence and Objectivity</h2>
          <p>
            Internal audits will be conducted by qualified and independent personnel who are free from
            conflicts of interest. Auditors will maintain objectivity and impartiality throughout the audit
            process.
          </p>
        </li>
        <li>
          <h2>Audit Planning</h2>
          <p>
            Audit planning will be based on a risk-based approach, considering the significance and complexity
            of COITB's operations, as well as regulatory requirements. The audit plan will be approved by
            [Designated Authority/Committee] and periodically reviewed and updated as necessary.
          </p>
        </li>
        <li>
          <h2>Audit Execution</h2>
          <p>
            Auditors will perform audits in accordance with professional standards and COITB's policies and
            procedures. Audit procedures may include interviews, document reviews, observation of
            processes, and testing of controls. Auditors will exercise due professional care and discretion in
            gathering and analyzing evidence.
          </p>
        </li>
        <li>
          <h2>Reporting</h2>
          <p>
            Audit findings, conclusions, and recommendations will be documented in audit reports. Reports
            will be clear, concise, and supported by sufficient evidence. Management will be provided with an
            opportunity to respond to audit findings and proposed recommendations. Final audit reports will be
            issued to [Designated Authority/Committee] and relevant stakeholders.
          </p>
        </li>
        <li>
          <h2>Follow-up and Monitoring</h2>
          <p>
            Management is responsible for implementing corrective actions in response to audit
            recommendations within agreed-upon timelines. The Internal Audit function will monitor the
            implementation of corrective actions and report on the status of outstanding issues to [Designated
            Authority/Committee].
          </p>
        </li>
        <li>
          <h2>Confidentiality and Access to Information</h2>
          <p>
            Auditors will maintain the confidentiality of audit information and exercise discretion in handling
            sensitive data. Access to audit documentation will be restricted to authorized personnel only, and
            information will be disclosed only as required by law or with appropriate consent.
          </p>
        </li>
        <li>
          <h2>Compliance</h2>
          <p>
            COITB's Internal Audit activities will comply with relevant laws, regulations, and professional
            standards. Any deviations from this policy must be approved by [Designated Authority/Committee].
          </p>
        </li>
        <li>
          <h2>Review and Revision</h2>
          <p>
            This policy will be reviewed periodically to ensure its effectiveness and relevance. Amendments
            may be made as necessary with the approval of [Designated Authority/Committee].
          </p>
        </li>
        <li>
          <h2>Communication and Training</h2>
          <p>
            This policy will be communicated to all relevant personnel within COITB. Training on internal audit
            processes, procedures, and expectations will be provided to auditors and other stakeholders as
            needed.
          </p>
        </li>
        <li>
          <h2>Responsibility</h2>
          <p>
            The [Designated Authority/Committee] is responsible for overseeing the implementation and
            adherence to this Internal Audit Policy.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default InternalAuditPolicy;
