import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

export default function RetestingPolicy() {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Retesting Policy:</h1>
      <ol>
        <li>
          <h2>Retesting Policy</h2>
          <p>
            Candidates who do not achieve a passing score on their first attempt
            may retake the exam after a waiting period of 7 days. The waiting
            period is intended to allow candidates time to review and prepare
            for the exam.
          </p>
        </li>
        <li>
          <h2>Retesting Process</h2>
          <p>
            Candidates who wish to retake the exam must submit a new
            registration form and pay the exam fee. The exam will be scheduled
            based on availability at the testing center or online proctoring
            platform.
          </p>
          <Link to="/credentials" className="back-button">
        Credentials page
        </Link>
        </li>
      </ol>
    </div>
  );
}
