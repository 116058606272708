import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const candidatePhotograph = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Candidate Photograph</h1>

      <p>
        To uphold the highest security and authentication standards, all COITB
        score reports issued from COITB will include the
        candidate’s photograph. Test center administrators are mandated to
        photograph each candidate before testing. Candidates unwilling to comply
        with this requirement will be ineligible to take COITB exams. For
        additional information on candidate photographs, please consult the ID
        and Photo Requirements section.
      </p>
    </div>
  );
};

export default candidatePhotograph;
