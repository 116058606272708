import "./ContactPage.css";
import "./ContactResponsivness.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaClock } from "react-icons/fa6";

export default function Contact() {
    return (
        <div className='contactContainer'>
            <div className='hero'>
                <div className='heroOverlay'></div>
            </div>
            <div className='heroContent'>
                <h1>Reach Out!</h1>
                <h4>We look forward to hearing from you.</h4>
            </div>
            <div className='formContainer'>
                <div className='contactInfo'>
                    <div className='iconsContainer'>
                        <div className='topIcons'>
                            <div className='icons'>
                                <FaClock size={30} />
                                <div>Mon-Fri: 9AM-5PM CST</div>
                            </div>
                            <div className='icons'>
                                <FaMapMarkerAlt size={30} />
                                <div>
                                    5600 N. May Ave., Suite 310, Oklahoma City,
                                    OK 73112
                                </div>
                            </div>
                        </div>
                        <div className='btmIcons'>
                            <div className='icons'>
                                <FaPhoneAlt size={30} />
                                <div>1+ (877) 402-0840</div>
                            </div>
                            <div className='icons'>
                                <MdOutlineEmail size={40} />
                                <div>support@coitb.org</div>
                            </div>
                        </div>
                    </div>
                </div>
                <iframe
                    src='https://forms.monday.com/forms/embed/690811b433684ac7c46519ce893fc217?r=use1'
                    style={{
                        width: "100%",
                        height: "100%",
                        boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.5)",
                        borderRadius: "0px 0px 15px 15px",
                    }}
                    className='iframe'
                ></iframe>
            </div>
        </div>
    );
}


