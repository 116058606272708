import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const onlineexampolicy = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">
        Online Proctored Reschedule and Cancellation Policies
      </h1>

      <h2 className="title">Reschedule Policy:</h2>

      <p>
        To reschedule your online proctored exam, please contact support@coitb.org up until the scheduled start
        time of your appointment. Failure to reschedule before the appointment
        time or failure to appear for your appointment will result in the
        forfeiture of your exam fee.
      </p>
      <h3 className="title">Cancellation Policy:</h3>

      <p>
        To cancel your online proctored exam, please contact support@coitb.org up until the scheduled start
        time of your appointment. Failure to cancel before the appointment time
        or failure to appear for your appointment will result in the forfeiture
        of your exam fee.
      </p>
    </div>
  );
};

export default onlineexampolicy;
