import React from "react";
import "./testingPage.css";
import { Link } from "react-router-dom";

const examFeedbackPage = () => {
  return (
    <div className="container">
      <Link to="/testing-policies" className="back-button">
        Back to Testing Policies
      </Link>
      <h1 className="title">Exam Feedback</h1>

      <p>
        We value the feedback and concerns of our candidates regarding the
        examination experience. Should you wish to provide input or raise issues
        about specific exam questions, you are welcome to submit a formal
        feedback ticket. This feedback is invaluable as it is reviewed by our
        dedicated committee of subject matter experts. Please note, while all
        submissions are considered, COITB does not provide individual responses
        to comments pertaining to exam content. This policy ensures that our
        focus remains on enhancing the exam experience while preserving the
        confidentiality and security of our exam content.
      </p>
    </div>
  );
};

export default examFeedbackPage;
