import React from 'react';
import "./policiesPage.css"; 
import { Link } from 'react-router-dom';

const DocumentControlCertificationPolicy = () => {
  return (
    <div className="container">
    <Link to="/policies-page" className="back-button">Back to Policies</Link>
      <h1 className="policy-title">Document Control Certification Program Policy</h1>
      <h2>1. Purpose</h2>
      <p>
        The purpose of the Document Control Certification Program Policy is to establish guidelines and
        procedures for certifying document control professionals within the Coalition of Inclusive Tech
        Businesses COITB. This program aims to ensure that individuals responsible for managing
        documents possess the necessary knowledge, skills, and competencies to effectively control and
        maintain organizational records.
      </p>
      <h2>2. Scope</h2>
      <p>
        This policy applies to all employees, contractors, and volunteers involved in document control
        activities within COITB. It covers the eligibility criteria, certification process, renewal requirements,
        and revocation procedures for document control certification.
      </p>
      <h2>3. Objectives</h2>
      <ul>
        <li> To standardize document control practices across COITB and ensure compliance with applicable
          laws, regulations, and industry standards.</li>
        <li> To enhance the quality, accuracy, and integrity of organizational documents and records.</li>
        <li> To provide professional development opportunities for document control professionals and
          promote continuous improvement in document management processes.</li>
      </ul>
      <h2>4. Certification Eligibility</h2>
      <p>
        To be eligible for certification, candidates must meet the following criteria:
      </p>
      <ul>
        <li> Demonstrated experience in document control or related field, typically a minimum of [X] years.</li>
        <li> Completion of relevant training or education programs in document management, information
          governance, or related disciplines.</li>
        <li> Adherence to COITB's code of conduct and ethical standards.</li>
        <li> Compliance with any additional eligibility requirements specified by the certification body.</li>
      </ul>
      <h2>5. Certification Process</h2>
      <p>
        The certification process consists of the following steps:
      </p>
      <ol>
        <li>Submission of an application for certification, including relevant documentation of qualifications
          and experience.</li>
        <li>Successful completion of a certification examination covering key competencies in document
          control, such as document classification, version control, retention schedules, and security
          protocols.</li>
        <li>Evaluation of practical skills through case studies, simulations, or other assessment methods, as
          determined by the certification body.</li>
        <li>Review and approval of the application by the certification body.</li>
        <li>Issuance of a document control certification upon successful completion of all requirements.</li>
      </ol>
      <h2>6. Renewal Requirements</h2>
      <p>
        Document control certifications will be valid for a specified period, typically [X] years, subject to
        renewal upon meeting the following requirements:
      </p>
      <ul>
        <li> Completion of continuing education or professional development activities relevant to document
          control, as determined by the certification body.</li>
        <li> Payment of renewal fees, if applicable.</li>
        <li> Compliance with COITB's code of conduct and ethical standards.</li>
        <li> Submission of a renewal application demonstrating ongoing competency in document control
          practices.</li>
      </ul>
      <h2>7. Revocation Procedures</h2>
      <p>
        Certification may be revoked or suspended in cases of:
      </p>
      <ul>
        <li> Violation of COITB's code of conduct or ethical standards.</li>
        <li> Failure to comply with certification renewal requirements.</li>
        <li> Misrepresentation of qualifications or fraudulent conduct.</li>
        <li> Other grounds as determined by the certification body.</li>
      </ul>
      <p>
        Revocation or suspension decisions will be made by the certification body following a fair and
        impartial review process, with opportunities for appeal as outlined in COITB's policies and
        procedures.
      </p>
      <h2>8. Review and Revision</h2>
      <p>
        This policy will be reviewed periodically to ensure its effectiveness and relevance. Amendments
        may be made as necessary with the approval of [Designated Authority/Committee].
      </p>
      <h2>9. Communication and Training</h2>
      <p>
        This policy will be communicated to all relevant stakeholders within COITB. Training on document
        control best practices, certification requirements, and procedures will be provided to candidates
        and current document control professionals as needed.
      </p>
    </div>
  );
};

export default DocumentControlCertificationPolicy;
